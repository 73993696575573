import { Grid } from '@strapi/design-system/Grid';
import { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { getChangedFields } from '../../../helpers/getChangedFields';
import { useAppSelector } from '../../../store/hooks';
import { StepContext } from '../../../types';
import { SEO } from '../SEO';
import { Step } from './step';

export const Step5: FC = () => {
  const { prev, save, submit } = useOutletContext<StepContext>();
  const { seo } = useAppSelector(state => state.offer.form);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
    formState: { errors, isDirty, dirtyFields }
  } = useForm({
    reValidateMode: 'onBlur',
    defaultValues: {
      seo
    }
  });

  const cleanCallback = useCallback(() => {
    reset({
      seo: {
        metaTitle: '',
        metaDescription: '',
        sharedImage: {
          media: null,
          alt: ''
        }
      }
    }, { keepDirty: true });
  }, []);

  const resetCallback = useCallback(() => {
    reset({ seo });
  }, []);

  const onSubmit = handleSubmit((fields) => {
    submit(getChangedFields(fields, dirtyFields));
  });

  const saveCallback = () => {
    trigger().then((isValid) => {
      if (isValid) save(getChangedFields(getValues(), dirtyFields));
    });
  };

  useEffect(() => {
    reset({ seo });
  }, [seo]);

  return <Step
    title="Suchmaschinen Optimierung"
    onSubmit={onSubmit}
    prevCallback={prev}
    cleanCallback={cleanCallback}
    resetCallback={resetCallback}
    isDirty={isDirty}>
    <input id="save" hidden onClick={saveCallback} />
    <Grid gap={5}>
      <SEO register={register} control={control} errors={errors} setValue={setValue} />
    </Grid>
  </Step>;
};
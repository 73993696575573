import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { STRAPI_GRAPHQL } from '../config';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const httpLink = createHttpLink({
  uri: STRAPI_GRAPHQL
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('ks-jwt');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache({
    typePolicies: {
      Offer: { merge: true },
      UploadFile: { merge: true },
      FieldOfActivity: { merge: true },
      TypeOfEmployment: { merge: true },
      EmploymentTime: { merge: true },
      Tag: { merge: true },
      Question: { merge: true },
      Company: { merge: true }
    }
  }),
});

export default client;

import { Grid } from '@strapi/design-system/Grid';
import { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { getChangedFields } from '../../../helpers/getChangedFields';
import { useAppSelector } from '../../../store/hooks';
import { StepContext } from '../../../types';
import { Location } from '../Location';
import { Step } from './step';

export const Step6: FC = () => {
  const { prev, save, submit } = useOutletContext<StepContext>();
  const { location } = useAppSelector(state => state.offer.form);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
    formState: { errors, isDirty, dirtyFields }
  } = useForm({
    reValidateMode: 'onBlur',
    defaultValues: {
      location
    }
  });

  const cleanCallback = useCallback(() => {
    reset({ location: null }, { keepDirty: true });
  }, []);

  const resetCallback = useCallback(() => {
    reset({ location });
  }, []);

  const onSubmit = handleSubmit((fields) => {
    submit(getChangedFields(fields, dirtyFields));
  });

  const saveCallback = () => {
    trigger().then((isValid) => {
      if (isValid) save(getChangedFields(getValues(), dirtyFields));
    });
  };

  useEffect(() => {
    reset({ location });
  }, [location]);

  return <Step
    title="Standort"
    onSubmit={onSubmit}
    prevCallback={prev}
    cleanCallback={cleanCallback}
    resetCallback={resetCallback}
    isDirty={isDirty}>
    <input id="save" hidden onClick={saveCallback} />
    <Grid gap={5}>
      <Location register={register} control={control} setValue={setValue} errors={errors} />
    </Grid>
  </Step>;
};
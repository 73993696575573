import { Box } from '@strapi/design-system/Box';
import { IconButton } from '@strapi/design-system/IconButton';
import { Stack } from '@strapi/design-system/Stack';
import { Flex } from '@strapi/design-system/Flex';
import { Grid, GridItem } from '@strapi/design-system/Grid';
import { FieldLabel } from '@strapi/design-system/Field';
import Trash from '@strapi/icons/Trash';
import { FC, useMemo } from 'react';
import { Controller, Control, FieldErrors, UseFormRegister, UseFormSetValue, useWatch } from 'react-hook-form';
import { Enum_Componentmainaddress_Country } from '../../../generated/graphql-types';
import { getError } from '../../../helpers/errors';
import { OfferState } from '../../../types';
import { Input } from '../../Inputs/Input';
import { Select } from '../../Inputs/Select';
import { AddButton } from '../AddButton';
import { FillButton } from '../FillButton';

const NEW_ADDRESS: NonNullable<OfferState['location']>['address'] = {
  street: '',
  locality: '',
  region: '',
  postalCode: '',
  country: 'DE' as Enum_Componentmainaddress_Country
}

const NEW_COORDINATES: NonNullable<OfferState['location']>['coordinates'] = {
  lat: null,
  long: null
};

export const Location: FC<{
  register: UseFormRegister<any>
  control: Control<any>
  errors: FieldErrors<any>
  setValue: UseFormSetValue<any>
}> = ({ register, control, errors, setValue }) => {
  const { location } = useWatch({ control });

  const add: (field?: 'address' | 'coordinates') => () => void = useMemo(
    () => (field) => () => {
      const value = { address: location?.address ?? null, coordinates: location?.coordinates ?? null };
      if (field === 'address') {
        value.address = NEW_ADDRESS;
      }
      if (field === 'coordinates') {
        value.coordinates = NEW_COORDINATES;
      }
      setValue('location', value as OfferState['location'], { shouldDirty: true });
    },
    [location]
  );

  const remove: (field?: 'address' | 'coordinates') => () => void = useMemo(
    () => (field) => () => {
      const value = field ? {
        address: field === 'address' ? null : location?.address ?? null,
        coordinates: field === 'coordinates' ? null : location?.coordinates ?? null
      } : null;
      setValue('location', value as OfferState['location'], { shouldDirty: true });
    },
    [location]
  );

  return <GridItem col={12}>
    <Stack spacing={1}>
      <Flex justifyContent="space-between">
        <FieldLabel>Adressdaten</FieldLabel>
        {!!location && <IconButton onClick={remove()} noBorder label="Delete" icon={<Trash />} />}
      </Flex>
      <Box background="neutral100" borderWidth="1px" borderColor="neutral200" borderStyle="solid" hasRadius>
        {!!location
          ? <>
            <Stack padding={5} spacing={1}>
              <Flex justifyContent="space-between">
                <FieldLabel>Adresse</FieldLabel>
                {!!location?.address &&
                  <IconButton onClick={remove('address')} noBorder label="Delete" icon={<Trash />} />}
              </Flex>
              <Box borderWidth="1px" borderColor="neutral200" borderStyle="solid" hasRadius>
                {!!location.address
                  ? <Box>
                    <Grid gap={2} padding={5}>
                      <GridItem col={12}>
                        <Input
                          label="Straße"
                          required
                          error={errors.location?.['address']?.street?.message}
                          {...register('location.address.street', { required: getError('street') })}
                          labelAction={<FillButton field="location.address.street" setValue={setValue} />} />
                      </GridItem>
                      <GridItem col={12}>
                        <Input
                          label="Ort"
                          required
                          error={errors.location?.['address']?.locality?.message}
                          {...register('location.address.locality', { required: getError('locality') })}
                          labelAction={<FillButton field="location.address.locality" setValue={setValue} />} />
                      </GridItem>
                      <GridItem col={12}>
                        <Input
                          label="Region"
                          {...register('location.address.region')}
                          labelAction={<FillButton field="location.address.region" setValue={setValue} />} />
                      </GridItem>
                      <GridItem col={6}>
                        <Input
                          label="PLZ"
                          required
                          error={errors.location?.['address']?.postalCode?.message}
                          {...register('location.address.postalCode', { required: getError('postal code') })}
                          labelAction={<FillButton field="location.address.postalCode" setValue={setValue} />} />
                      </GridItem>
                      <GridItem col={6}>
                        <Controller
                          name="location.address.country"
                          control={control}
                          rules={{ required: getError('country') }}
                          render={({ field }) => <Select
                            {...field}
                            label="Land"
                            required
                            error={errors?.location?.['address']?.country?.message}
                            isClearable={false}
                            options={[
                              { value: 'DE', label: 'Germany' },
                              { value: 'NL', label: 'Niederlands' }
                            ]}
                            labelAction={<FillButton field="location.address.country" setValue={setValue} />} />} />
                      </GridItem>
                    </Grid>
                  </Box>
                  : <AddButton type="" onClick={add('address')} />}
              </Box>
            </Stack>
            <Stack padding={5} spacing={1}>
              <Flex justifyContent="space-between">
                <FieldLabel>Koordinaten</FieldLabel>
                {!!location?.coordinates &&
                  <IconButton onClick={remove('coordinates')} noBorder label="Delete" icon={<Trash />} />}
              </Flex>
              <Box borderWidth="1px" borderColor="neutral200" borderStyle="solid" hasRadius>
                {!!location.coordinates
                  ? <Box>
                    <Grid gap={2} padding={5}>
                      <GridItem col={6}>
                        <Input
                          label="Latitude"
                          required
                          type="number"
                          min={-90}
                          max={90}
                          step={0.01}
                          error={errors?.location?.['coordinates']?.lat?.message}
                          {...register('location.coordinates.lat', {
                            required: getError('latitude'),
                            valueAsNumber: true,
                            min: { value: -90, message: 'Value must be in [-90, 90]' },
                            max: { value: 90, message: 'Value must be in [-90, 90]' }
                          })}
                          labelAction={<FillButton field="location.coordinates.lat" setValue={setValue} />} />
                      </GridItem>
                      <GridItem col={6}>
                        <Input
                          label="Longitude"
                          required
                          type="number"
                          min={-180}
                          max={180}
                          step={0.01}
                          error={errors?.location?.['coordinates']?.long?.message}
                          {...register('location.coordinates.long', {
                            required: getError('longitude'),
                            valueAsNumber: true,
                            min: { value: -180, message: 'Value must be in [-180, 180]' },
                            max: { value: 180, message: 'Value must be in [-180, 180]' }
                          })}
                          labelAction={<FillButton field="location.coordinates.long" setValue={setValue} />} />
                      </GridItem>
                    </Grid>
                  </Box>
                  : <AddButton type="" onClick={add('coordinates')} />}
              </Box>
            </Stack>
          </>
          : <AddButton type="" onClick={add()} />}
      </Box>
    </Stack>
  </GridItem>
};

import { Textarea as TextArea } from '@strapi/design-system/Textarea';
import { Box } from '@strapi/design-system/Box';
import { forwardRef } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

type TextareaProps = {
  label?: string
  placeholder?: string
  required?: boolean
  hint?: string
  error?: string
  labelAction?: JSX.Element
} & ControllerRenderProps<any>;

export const Textarea = forwardRef<HTMLElement, TextareaProps>(({
  placeholder,
  label,
  required = false,
  hint,
  error,
  value,
  labelAction,
  ...rest
}, ref) => {
  return <TextArea
    {...rest}
    placeholder={placeholder}
    label={label}
    labelAction={<Box paddingLeft={2}>{labelAction}</Box>}
    hint={hint}
    error={error}
    required={required}
    ref={ref}>
    {value}
  </TextArea>
});

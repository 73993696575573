import { Box } from '@strapi/design-system/Box';
import { Stack } from '@strapi/design-system/Stack';
import { Button } from '@strapi/design-system/Button';
import { Popover } from '@strapi/design-system/Popover';
import FilterIcon from '@strapi/icons/Filter';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import {
  useGetActivityFieldsQuery,
  useGetCompaniesQuery,
  useGetEmploymentTimesQuery,
  useGetEmploymentTypesQuery,
  useGetPlacesQuery
} from '../../../generated/graphql-types';
import { getOptions } from '../../../helpers/getOptions';
import { FilterField, FilterState } from '../../../types';
import { Select, SelectOption } from '../../Inputs/Select';

export const Filter: FC<{
  filterState: FilterState,
  onChange: (state: FilterState) => void
}> = ({ filterState, onChange }) => {
  const [visible, setVisible] = useState(false);
  const buttonRef = useRef();
  const [field, setField] = useState<FilterField>();
  const [values, setValues] = useState<string[]>([]);

  const { data: companiesData } = useGetCompaniesQuery();
  const { data: placesData } = useGetPlacesQuery();
  const { data: activityFieldsData } = useGetActivityFieldsQuery();
  const { data: employmentTypesData } = useGetEmploymentTypesQuery();
  const { data: employmentTimesData } = useGetEmploymentTimesQuery();

  const companies = useMemo(
    () => getOptions(companiesData?.companies?.data, 'name', 'key'),
    [companiesData]
  );

  const places = useMemo(
    () => getOptions(placesData?.places?.data, 'name', 'key'),
    [placesData]
  );

  const activityFields = useMemo(
    () => getOptions(activityFieldsData?.fieldOfActivities?.data, 'name', 'key'),
    [activityFieldsData]
  );

  const employmentTypes = useMemo(
    () => getOptions(employmentTypesData?.typeOfEmployments?.data, 'name', 'key'),
    [employmentTypesData]
  );

  const employmentTimes = useMemo(
    () => getOptions(employmentTimesData?.employmentTimes?.data, 'name', 'key'),
    [employmentTimesData]
  );

  const options: Record<FilterField, SelectOption[]> = useMemo(() => ({
    company: companies,
    place: places,
    field_of_activities: activityFields,
    type_of_employments: employmentTypes,
    employment_times: employmentTimes
  }), [companies, places, activityFields, employmentTypes, employmentTimes]);

  const apply = useCallback(() => {
    if (field && values.length) {
      onChange({
        ...filterState,
        [field]: {
          keys: values,
          labels: options[field].filter(o => values.includes(o.value)).map(o => o.label)
        }
      });
      setVisible(false);
      setField(undefined);
      setValues([]);
    }
  }, [field, values, filterState]);

  return <Box>
    <Button
      startIcon={<FilterIcon />}
      title="Filters"
      variant="tertiary"
      onClick={() => setVisible(!visible)}
      ref={buttonRef}>Filters</Button>
    {visible && <Popover source={buttonRef} spacing={2} style={{ overflow: 'visible', maxHeight: 'unset' }}>
      <Box padding={2} width="500px">
        <Stack spacing={2}>
          <Select
            name="field"
            value={field}
            onChange={v => setField(v)}
            options={[
              { value: 'company', label: 'Unternehmen' },
              { value: 'place', label: 'Arbeitsort' },
              { value: 'field_of_activities', label: 'Art der Anstellung' },
              { value: 'type_of_employments', label: 'Tätigkeitenbereich' },
              { value: 'employment_times', label: 'Arbeitszeit' }
            ]} />
          {!!field && <Box width="100%">
            <Select
              name="values"
              value={values}
              onChange={v => setValues(v)}
              isMulti
              options={options[field]} />
          </Box>}
          <Button size="L" fullWidth onClick={apply}>Anwenden</Button>
        </Stack>
      </Box>
    </Popover>}
  </Box>
};
import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import client from './apollo/client';
import { CreateOffer } from './components/CreateOffer';
import { Login } from './components/Login';
import { Home } from './components/Home';
import { Offer } from './components/Offer';
import { UserProvider, useUserContext } from './contexts/userContext/userContext';
import { ThemeProvider } from '@strapi/design-system/ThemeProvider';
import { lightTheme } from '@strapi/design-system/themes';
import store from './store/store';

function RequireAuth({ children }: { children: JSX.Element }) {
  const { token } = useUserContext();
  const location = useLocation();

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <UserProvider>
          <ThemeProvider theme={lightTheme}>
            <Routes>
              <Route path="/" element={<RequireAuth><Home /></RequireAuth>} />
              <Route path="/login" element={<Login />} />
              <Route path="/job/create/*" element={<RequireAuth><CreateOffer /></RequireAuth>} />
              <Route path="/job/:id/*" element={<RequireAuth><Offer /></RequireAuth>} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </ThemeProvider>
        </UserProvider>
      </ApolloProvider>
    </Provider>
  );
}

export default App;

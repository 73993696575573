import { Button } from '@strapi/design-system/Button';
import { Dialog, DialogBody, DialogFooter } from '@strapi/design-system/Dialog';
import { Flex } from '@strapi/design-system/Flex';
import { Stack } from '@strapi/design-system/Stack';
import { Typography } from '@strapi/design-system/Typography';
import ExclamationMarkCircle from '@strapi/icons/ExclamationMarkCircle';
import Trash from '@strapi/icons/Trash';

export const Confirmation = ({
  message = 'Möchten Sie wirklich den Eintrag löschen?',
  isShow,
  setIsShow,
  onConfirm
}) => {
  return <Dialog onClose={() => setIsShow(false)} title="Bëstatigung" isOpen={isShow}>
    <DialogBody icon={<ExclamationMarkCircle />}>
      <Stack spacing={2}>
        <Flex justifyContent="center">
          <Typography id="confirm-description">{message}</Typography>
        </Flex>
      </Stack>
    </DialogBody>
    <DialogFooter
      startAction={<Button onClick={() => setIsShow(false)} variant="tertiary">
        Abbrechen
      </Button>}
      endAction={<Button onClick={onConfirm} variant="danger-light" startIcon={<Trash />}>
        Bestätigen
      </Button>} />
  </Dialog>
};

import { Button } from '@strapi/design-system/Button';
import { useCallback, useEffect, useRef, useState } from 'react';

export const ButtonWithTimer = ({ label, onClick, blockTime = 60, isBlocked, setIsBlocked }) => {
  const timer = useRef<number>();
  const [time, setTime] = useState<number | null>(null);

  const cycle = useCallback((counter) => {
    setTime(counter);
    if (counter < 1) end();
  }, []);

  const start = useCallback(() => {
    let counter = blockTime;
    setIsBlocked(true);
    cycle(counter);
    timer.current = window.setInterval(() => cycle(--counter), 1000);
  }, []);

  const end = useCallback(() => {
    window.clearInterval(timer.current);
    setTime(null);
    setIsBlocked(false);
  }, []);

  useEffect(() => {
    if (isBlocked) {
      start();
    } else {
      end();
    }
  }, [isBlocked]);

  useEffect(() => {
    return () => {
      window.clearInterval(timer.current);
    }
  }, []);

  return <Button
    type="button"
    size="S"
    variant="secondary"
    disabled={isBlocked}
    onClick={onClick}>{label} {time ? `(${time})` : ``}</Button>
};
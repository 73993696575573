import apolloClient from '../apollo/client';
import {
  CheckOfferSlugQuery,
  CheckOfferSlugQueryVariables,
  CheckQuestionNameQuery,
  CheckQuestionNameQueryVariables
} from '../generated/graphql-types';
import { CHECK_OFFER_SLUG, CHECK_QUESTION_NAME } from '../queries';

const replace: (text: string) => string = (text) => {
  return text
    .toLowerCase()
    .trim()
    .replace(/[^a-zäöüß0-9_\s-]/ig, '')
    .replace(/\s+/ig, '-')
    .replace(/ä/ig, 'ae')
    .replace(/ö/ig, 'oe')
    .replace(/ü/ig, 'ue')
    .replace(/ß/ig, 'ss')
    .replace(/-?\d+$/, '');
};

const slugify: (
  text?: string,
  fallback?: string,
  check?: (value: string) => Promise<boolean>
) => Promise<string> = async (text, fallback = 'slug', check) => {
  let slug = text ? replace(text) : fallback;
  let postfix = '';

  if (!!check) {
    try {
      for (let i = 1; ; i++) {
        if (await check(slug + postfix)) {
          break;
        }
        postfix = `-${i}`;
      }
    } catch (e: any) {
      console.log(e.toString());
    }
  }

  return slug + postfix;
};

export const getOfferSlug: (text?: string, fallback?: string) => Promise<string> = async (text, fallback = 'offer') => {
  return slugify(text, fallback, async (slug) => {
    const { data } = await apolloClient.query<CheckOfferSlugQuery, CheckOfferSlugQueryVariables>({
      query: CHECK_OFFER_SLUG,
      variables: { slug },
      fetchPolicy: 'no-cache'
    });
    return !data.offers?.data?.[0]?.id;
  });
};

export const getQuestionName: (text?: string, fallback?: string) => Promise<string> = async (text, fallback = 'question') => {
  return slugify(text, fallback, async (slug) => {
    const { data } = await apolloClient.query<CheckQuestionNameQuery, CheckQuestionNameQueryVariables>({
      query: CHECK_QUESTION_NAME,
      variables: { name: slug },
      fetchPolicy: 'no-cache'
    });
    return !data.questions?.data?.[0]?.id;
  });
};

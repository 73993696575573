import { ModalLayout, ModalBody, ModalHeader, ModalFooter } from '@strapi/design-system/ModalLayout';
import { Typography } from '@strapi/design-system/Typography';
import { Button } from '@strapi/design-system/Button';
import { FC, useCallback } from 'react';
import { NewQuestion } from '../NewQuestion';
import styles from './QuestionModal.module.scss';

type QuestionModalProps = {
  isVisible: boolean
  setIsVisible: (value: boolean) => void
  onSelect: (id: string) => void
}

export const QuestionModal: FC<QuestionModalProps> = ({
  isVisible,
  setIsVisible,
  onSelect
}) => {
  const add = useCallback((id: string) => {
    onSelect(id);
    setIsVisible(false);
  }, [onSelect]);

  return isVisible
    ? <>
      <ModalLayout className={styles.Modal} onClose={() => setIsVisible(false)} labelledBy="title">
        <ModalHeader>
          <Typography fontWeight="bold" textColor="neutral800" as="h2" id="title">
            Bewerberfragen
          </Typography>
        </ModalHeader>
        <ModalBody>
          <NewQuestion add={add} />
        </ModalBody>
        <ModalFooter endActions={<Button>Hinzufügen</Button>} />
      </ModalLayout>
    </>
    : null;
};

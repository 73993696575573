import { Box } from '@strapi/design-system/Box';
import { Flex } from '@strapi/design-system/Flex';
import { Typography } from '@strapi/design-system/Typography';
import PlusCircle from '@strapi/icons/PlusCircle';
import { FC } from 'react';

export const AddButton: FC<{ type: string, onClick: () => void }> = ({ type, onClick }) => {
  return <button
    type="button"
    onClick={onClick}
    style={{ width: '100%', height: '120px' }}>
    <Flex direction="column">
      <Box paddingBottom={2}><PlusCircle fontSize={24} /></Box>
      <Typography
        variant="pi"
        fontWeight="bold">
        {type || 'Keine Einträge. Klicken um einen neuen Eintrag hinzuzufügen.'}
      </Typography>
    </Flex>
  </button>
};

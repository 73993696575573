import { Grid, GridItem } from '@strapi/design-system/Grid';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { getChangedFields } from '../../../helpers/getChangedFields';
import { useAppSelector } from '../../../store/hooks';
import { LOCALE, StepContext } from '../../../types';
import { CarouselInput } from '../../CarouselInput';
import { Input } from '../../Inputs/Input';
import { Benefits } from '../Benefits';
import { Step } from './step';

export const Step3: FC = () => {
  const { prev, save, submit } = useOutletContext<StepContext>();
  const [searchParams] = useSearchParams();
  const locale = searchParams.get('locale') ?? LOCALE.DE;
  const {
    benefits,
    mainImage,
    images,
    videoURL
  } = useAppSelector(state => state.offer.form);

  const isDE = useMemo(() => locale === 'de', [locale]);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
    formState: { errors, isDirty, dirtyFields }
  } = useForm({
    reValidateMode: 'onBlur',
    defaultValues: {
      benefits,
      mainImage,
      images,
      videoURL
    }
  });

  const cleanCallback = useCallback(() => {
    reset({
      benefits: [],
      mainImage: null,
      images: [],
      videoURL: ''
    }, { keepDirty: true });
  }, []);

  const resetCallback = useCallback(() => {
    reset({
      benefits,
      mainImage,
      images,
      videoURL
    });
  }, []);

  const onSubmit = handleSubmit((fields) => {
    submit(getChangedFields(fields, dirtyFields));
  });

  const saveCallback = () => {
    trigger().then((isValid) => {
      if (isValid) save(getChangedFields(getValues(), dirtyFields));
    });
  };

  useEffect(() => {
    reset({
      benefits,
      mainImage,
      images,
      videoURL
    });
  }, [
    benefits,
    mainImage,
    images,
    videoURL
  ]);

  return <Step
    title="Vorteile & Mediadaten"
    onSubmit={onSubmit}
    prevCallback={prev}
    cleanCallback={cleanCallback}
    resetCallback={resetCallback}
    isDirty={isDirty}>
    <input id="save" hidden onClick={saveCallback} />
    <Grid gap={5}>
      <Benefits register={register} control={control} errors={errors} setValue={setValue} />

      {isDE && <>
        <GridItem col={6} s={12}>
          <Controller
            name="mainImage"
            control={control}
            render={({ field }) => <CarouselInput {...field} label="Bild" multiple={false} />} />
        </GridItem>
        <GridItem col={6} s={12}>
          <Controller
            name="images"
            control={control}
            render={({ field }) => <CarouselInput {...field} label="Bilder" />} />
        </GridItem>
        <GridItem col={6} s={12}>
          <Input
            label="Video"
            {...register('videoURL')} />
        </GridItem>
        <GridItem col={6} s={12} />
      </>}
    </Grid>
  </Step>;
};
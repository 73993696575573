import { Alert } from '@strapi/design-system/Alert';
import { Box } from '@strapi/design-system/Box';
import { Button } from '@strapi/design-system/Button';
import { EmptyStateLayout } from '@strapi/design-system/EmptyStateLayout';
import { Flex } from '@strapi/design-system/Flex';
import { Stack } from '@strapi/design-system/Stack';
import { IconButton } from '@strapi/design-system/IconButton';
import { BaseHeaderLayout } from '@strapi/design-system/Layout';
import { Searchbar, SearchForm } from '@strapi/design-system/Searchbar';
import { Table, Tbody, TFooter, Th, Thead, Tr } from '@strapi/design-system/Table';
import { Typography } from '@strapi/design-system/Typography';
import { VisuallyHidden } from '@strapi/design-system/VisuallyHidden';
import File from '@strapi/icons/File';
import Plus from '@strapi/icons/Plus';
import Search from '@strapi/icons/Search';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  OfferFiltersInput,
  useGetOffersQuery
} from '../../generated/graphql-types';
import { getTranslation } from '../../helpers/getTranslation';
import { FilterState, LOCALE } from '../../types';
import { Select } from '../Inputs/Select';
import { Layout } from '../Layout';
import { Pagination } from '../Pagination';
import { Preloader } from '../Preloader';
import { Filter } from './Filter';
import { Row } from './Row';
import styles from './Home.module.scss';
import { Tags } from './Tags';

export const Home = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const localeParam = searchParams.get('locale');
  const [preloader, setPreloader] = useState(false);
  const [error, setError] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [isSearch, setIsSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [locale, setLocale] = useState(localeParam ?? LOCALE.DE);
  const [filterState, setFilterState] = useState<FilterState>({});

  const filters: OfferFiltersInput = useMemo(() => ({
    ...Object.entries(filterState)
      .reduce((acc, [field, { keys }]) => ({
        ...acc,
        [field]: { key: { in: keys } }
      }), {}),
    ...searchTerm.length > 1 && { title: { containsi: searchTerm } }
  }), [filterState, searchTerm]);

  const { data, loading, refetch } = useGetOffersQuery({
    variables: {
      filters,
      pagination: { page: parseInt(searchParams.get('page') ?? '1'), pageSize },
      locale
    }
  });

  const count = useMemo(() => data?.offers?.meta.pagination.total ?? 0, [data]);
  const pageCount = useMemo(() => data?.offers?.meta.pagination.pageCount, [data]);

  const add = useCallback(() => {
    navigate('/job/create');
  }, [navigate]);

  const onSubmit = useCallback((evt) => {
    evt.preventDefault();
  }, []);

  useEffect(() => {
    if (locale) {
      setSearchParams({ locale });
    }
  }, [locale]);

  return <Layout
    header={<BaseHeaderLayout
      primaryAction={<Button size="L" onClick={add}>Neue Stellenanzeige</Button>}
      title="Stellenanzeigen"
      subtitle={`${count} Einträge`} />}>
    <Flex paddingBottom={5} justifyContent="space-between">
      <Stack horizontal spacing={2}>
        {!isSearch && <IconButton label="Search" icon={<Search />} onClick={() => setIsSearch(true)} />}
        {isSearch && <SearchForm onSubmit={onSubmit}>
          <Searchbar
            name="searchbar"
            onClear={() => setSearchTerm('')}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value.trim())}
            clearLabel="Clear"
            placeholder=""
            size="S">Searching</Searchbar>
        </SearchForm>}
        <Filter filterState={filterState} onChange={setFilterState} />
      </Stack>
      <Box width="150px">
        <Select
          name="locales"
          value={locale}
          onChange={v => setLocale(v)}
          isClearable={false}
          options={[
            { value: LOCALE.DE, label: getTranslation(LOCALE.DE) },
            { value: LOCALE.EN, label: getTranslation(LOCALE.EN) }
          ]}
          placeholder="Select locale"
        />
      </Box>
    </Flex>
    <Tags filterState={filterState} setFilterState={setFilterState} />
    {loading
      ? <Preloader />
      : !count
        ? <EmptyStateLayout
          icon={<File fontSize={50} />}
          content={!!searchTerm ? 'Keine Ergebnisse' : 'Noch keine Stellenanzeigen vorhanden'}
          action={!searchTerm && <Button variant="secondary" startIcon={<Plus />}>
            Neue Stellenanzeige
          </Button>} />
        : <>
          <Box className={styles.TableBox}>
            {!!error && <Box paddingBottom={5}>
              <Alert onClose={() => setError('')} closeLabel="Close alert" title="" variant="danger">{error}</Alert>
            </Box>}
            {preloader && <Preloader />}
            <Table
              colCount={5} rowCount={100}
              footer={<TFooter icon={<Plus />} onClick={add}>Neue Stellenanzeige</TFooter>}>
              <Thead>
                <Tr>
                  <Th>
                    <Typography variant="sigma">TITEL</Typography>
                  </Th>
                  <Th>
                    <Typography variant="sigma">UNTERNEHMEN</Typography>
                  </Th>
                  <Th>
                    <Typography variant="sigma">SPRACHE</Typography>
                  </Th>
                  <Th>
                    <Typography variant="sigma">STATUS</Typography>
                  </Th>
                  <Th>
                    <VisuallyHidden>Actions</VisuallyHidden>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.offers?.data.map((offer) => <Row
                  offer={offer} key={offer.id} setPreloader={setPreloader} setError={setError} refetch={refetch} />)}
              </Tbody>
            </Table>
          </Box>
          <Flex padding={5} justifyContent="end">
            <Select
              options={[
                { value: '10', label: '10' },
                { value: '20', label: '20' },
                { value: '50', label: '50' },
                { value: '100', label: '100' }
              ]}
              onChange={v => setPageSize(parseInt(v as string))}
              value={pageSize.toString()}
              name="pageSize"
              isClearable={false} />
            {pageCount && <Pagination page={parseInt(searchParams.get('page') ?? '1')} pageCount={pageCount} />}
          </Flex>
        </>
    }
  </Layout>;
};

import { Pagination as SPagination, NextLink, PageLink, PreviousLink } from '@strapi/design-system/Pagination';
import { FC, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

export const Pagination: FC<{
  page: number
  pageCount: number
  setPage?: (n: number) => void
  asButton?: boolean
}> = ({ page, pageCount, setPage, asButton = false }) => {
  const PageButton = ({ children, to, ...props }) => {
    const page = parseInt(to.match(/page=(\d+)/)?.[1] ?? '1');
    return <button
      {...props}
      type="button"
      onClick={() => setPage?.(page)}>{children}</button>;
  };

  const as = useMemo(() => asButton ? { as: PageButton } : { as: NavLink }, [asButton]);

  return <SPagination activePage={page} pageCount={pageCount}>
    <PreviousLink to={`?page=${page > 1 ? page - 1 : 1}`} {...as}>
      Go to previous page
    </PreviousLink>
    {Array(pageCount).fill(null).map((_, index) => (
      <PageLink
        key={index}
        number={index + 1}
        to={`?page=${index + 1}`}
        {...as}>
        Go to page ${index + 1}
      </PageLink>
    ))}
    <NextLink to={`?page=${page < pageCount ? page + 1 : pageCount}`} {...as}>
      Go to next page
    </NextLink>
  </SPagination>;
};

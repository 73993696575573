import { gql, useApolloClient } from '@apollo/client';
import { TextButton } from '@strapi/design-system/TextButton';
import { Typography } from '@strapi/design-system/Typography';
import { Stack } from '@strapi/design-system/Stack';
import { Tooltip } from '@strapi/design-system/Tooltip';
import Download from '@strapi/icons/Download';
import { FC, useCallback } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { getInnerValue } from '../../../helpers/getInnerValue';
import { Paths } from '../../../queries/types';
import { LOCALE, OfferState } from '../../../types';

export const FillButton: FC<{
  field: Paths<OfferState>,
  isRelation?: boolean,
  subquery?: string | null,
  handle?: ((value: any) => any) | null
  setValue: UseFormSetValue<any>
}> = ({ field, isRelation = false, subquery = null, handle = null, setValue }) => {
  const client = useApolloClient();
  const [searchParams] = useSearchParams();
  const locale = searchParams.get('locale') ?? LOCALE.DE;
  const forId = searchParams.get('for') ?? null;

  const fillField = useCallback(() => {
    const id = forId;
    try {
      if (id) {
        let subQuery = subquery || (isRelation ? `data{id}` : '');
        let fieldQuery = [...field.split('.'), subQuery]
          .reverse()
          .reduce((s, w) => s ? `${w}{${s}}` : w, '');
        const query = gql`query OfferField{offer(id:${id}){data{id attributes{${fieldQuery}}}}}`;
        client.query({ query })
          .then(({ data }) => {
            let value = getInnerValue(data?.offer?.data?.attributes, field);
            if (handle) {
              value = handle(value);
            } else if (!!value?.data?.id) {
              value = value.data.id;
            } else if (!!value?.data?.length) {
              value = value.data.map(e => e.id);
            }
            if (value) {
              setValue(field, value, { shouldDirty: true });
            }
          });
      }
    } catch (e) {
      console.log(e);
    }
  }, [forId, client]);

  return locale !== 'de'
    ? <Tooltip description="Fill field from german version">
      <TextButton onClick={fillField}>
        <Stack horizontal spacing={1}>
          <Download />
          <Typography style={{ color: "#4945ff" }} variant="pi">de</Typography>
        </Stack>
      </TextButton>
    </Tooltip>
    : null
};
import { Alert } from '@strapi/design-system/Alert';
import { Box } from '@strapi/design-system/Box';
import { Button } from '@strapi/design-system/Button';
import { Field, FieldError, FieldHint, FieldInput, FieldLabel } from '@strapi/design-system/Field';
import { Stack } from '@strapi/design-system/Stack';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useUserContext } from '../../contexts/userContext/userContext';
import { ButtonWithTimer } from './ButtonWithTimer';

export const CodeForm = ({ userId, onVerify }) => {
  const { verify, sendCode } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('Sicherheitscode wurde per E-Mail versendet');
  const [error, setError] = useState('');
  const [isBlockedEmail, setIsBlockedEmail] = useState(false);
  const [isBlockedSMS, setIsBlockedSMS] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<{ code: string }>({
    reValidateMode: 'onBlur',
    defaultValues: { code: '' }
  });

  const { code } = watch();

  useEffect(() => {
    if (error) setError('');
  }, [code]);

  const checkCode = ({ code }) => {
    if (userId && code) {
      setLoading(true);
      verify(userId, parseInt(code.trim()))
        .then(({ error }) => {
          if (error) {
            setError(error.message);
          } else onVerify();
        })
        .catch(e => {
          setError(e.toString());
        })
        .finally(() => setLoading(false));
    }
  };

  const sendEmail = useCallback(() => {
    setIsBlockedEmail(true);
    sendCode(userId, 'email')
      .then(({ send, error }) => {
        if (send) {
          setMessage('Sicherheitscode wurde per E-Mail versendet');
        }
        if (error) {
          setError(error.message);
          setIsBlockedEmail(false);
        }
      })
      .catch(e => {
        setError(e.toString());
        setIsBlockedEmail(false);
      });
  }, [userId]);

  const sendSMS = useCallback(() => {
    setIsBlockedSMS(true);
    sendCode(userId, 'sms')
      .then(({ send, error }) => {
        if (send) {
          setMessage('Sicherheitscode wurde per SMS versendet');
        }
        if (error) {
          setError(error.message);
          setIsBlockedSMS(false);
        }
      })
      .catch(e => {
        setError(e.toString());
        setIsBlockedSMS(false);
      });
  }, [userId]);

  const validate = (code) => {
    return /^\d{4}$/i.test(code.trim()) || 'Falsches Format';
  };

  return <form onSubmit={handleSubmit(checkCode)} noValidate>
    {!!message && <Box paddingTop={5} paddingBottom={5}>
      <Alert
        onClose={() => setMessage('')} closeLabel="Close alert" title=""
        variant="success">{message}</Alert>
    </Box>}
    <Box paddingTop={5} paddingBottom={3}>
      <Controller
        name="code"
        control={control}
        rules={{
          required: "Bitte den Sicherheitcode eingeben",
          validate
        }}
        render={({ field }) => <Field error={errors.code?.message}>
          <Stack spacing={1}>
            <FieldLabel required>Code</FieldLabel>
            <FieldInput {...field} type="text" placeholder="Sicherheitscode" />
            <FieldHint />
            <FieldError />
          </Stack>
        </Field>} />
    </Box>
    <Button
      type="submit"
      size="L"
      variant="success"
      disabled={loading}
      fullWidth>Bestätigen</Button>
    {!!error && <Box paddingTop={5}>
      <Alert
        onClose={() => setError('')} closeLabel="Close alert" title=""
        variant="danger">{error}</Alert>
    </Box>}
    {<Stack paddingTop={6} spacing={2} alignItems="flex-start">
      <ButtonWithTimer
        label="Code per E-Mail erneut senden" onClick={sendEmail} isBlocked={isBlockedEmail}
        setIsBlocked={setIsBlockedEmail} />
      <ButtonWithTimer
        label="Code per SMS senden" onClick={sendSMS} isBlocked={isBlockedSMS}
        setIsBlocked={setIsBlockedSMS} />
    </Stack>}
  </form>
};
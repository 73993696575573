import { gql } from '@apollo/client';

const uploadFileData = gql`
  fragment UploadFileData on UploadFile {
    url
    name
    alternativeText
    formats
    ext
    width
    height
  }
`;

export const GET_TOKEN = gql`
  mutation GetToken($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
      user {
        id
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser {
    me {
      username
      firstname
      lastname
      position
      email
      phone
    }
  }
`;

export const GET_OFFERS = gql`
  query GetOffers(
    $filters: OfferFiltersInput
    $publicationState: PublicationState = PREVIEW
    $pagination: PaginationArg = {}
    $locale: I18NLocaleCode = "de"
  ) {
    offers(
      filters: $filters
      publicationState: $publicationState
      pagination: $pagination
      locale: $locale
      sort: "updatedAt:desc"
    ) {
      data {
        id
        attributes {
          publishedAt
          title
          locale
          company {
            data {
              id
              attributes {
                name
              }
            }
          }
          localizations(publicationState: PREVIEW) {
            data {
              id
              attributes {
                locale
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageCount
          pageSize
        }
      }
    }
  }
`;

export const GET_OFFER = gql`
  query GetOffer($id: ID!) {
    offer(id: $id) {
      data {
        id
        attributes {
          publishedAt
          slug
          title
          description
          benefits{
            text
            description
            icon {
              data {
                id
                attributes {
                  ...UploadFileData
                }
              }
            }
          }
          images {
            data {
              id
              attributes {
                ...UploadFileData
              }
            }
          }
          location {
            address {
              street
              locality
              region
              postalCode
              country
            }
            coordinates {
              lat
              long
            }
          }
          contactPerson {
            name
            position
            email
            phone
            additional
            image {
              data {
                id
                attributes {
                  ...UploadFileData
                }
              }
            }
          }
          seo {
            metaTitle
            metaDescription
            sharedImage {
              media {
                data {
                  id
                  attributes {
                    ...UploadFileData
                  }
                }
              }
              alt
            }
            keywords
            preventIndexing
          }
          relatedOffers{
            data {
              id
              attributes {
                title
              }
            }
          }
          short_description
          mainImage {
            data {
              id
              attributes {
                ...UploadFileData
              }
            }
          }
          field_of_activities {
            data {
              id
              attributes {
                key
                name
              }
            }
          }
          type_of_employments {
            data {
              id
              attributes {
                key
                name
              }
            }
          }
          employment_times {
            data {
              id
              attributes {
                key
                name
              }
            }
          }
          questions {
            data {
              id
              attributes {
                question
              }
            }
          }
          withoutCV
          remote
          tags {
            data {
              id
              attributes {
                key
                name
              }
            }
          }
          company {
            data {
              id
            }
          }
          place {
            data {
              id
            }
          }
          locale
          priority
          videoURL
          localizations(publicationState: PREVIEW) {
            data {
              id
              attributes {
                locale
                slug
              }
            }
          }
        }
      }
    }
  }
  ${uploadFileData}
`;

export const CHECK_OFFER_SLUG = gql`
  query CheckOfferSlug($slug: String!) {
    offers(filters: {slug: {eq: $slug}} publicationState: PREVIEW locale: "all") {
      data {
        id
      }
    }
  }
`;

export const CHECK_QUESTION_NAME = gql`
  query CheckQuestionName($name: String!) {
    questions(filters: {name: {eq: $name}}) {
      data {
        id
      }
    }
  }
`;

export const CREATE_OFFER = gql`
  mutation CreateOffer($data: OfferInput!, $locale: I18NLocaleCode = "de") {
    createOffer(data: $data, locale: $locale) {
      data {
        id
        attributes {
          slug
        }
      }
    }
  }
`;

export const UPDATE_OFFER = gql`
  mutation UpdateOffer($id: ID!, $data: OfferInput!, $locale: I18NLocaleCode = "de") {
    updateOffer(id: $id, data: $data, locale: $locale) {
      data {
        id
        attributes {
          slug
        }
      }
    }
  }
`;

export const DELETE_OFFER = gql`
  mutation DeleteOffer($id: ID!) {
    deleteOffer(id: $id) {
      data {
        id
      }
    }
  }
`;

export const GET_FILES = gql`
  query GetFiles($ids: [ID], $pagination: PaginationArg = { page: 1, pageSize: 10 }) {
    uploadFiles(
      filters: {id: {in: $ids}}
      pagination: $pagination
      sort: "createdAt:desc"
    ) {
      data {
        id
        attributes {
          ...UploadFileData
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
  ${uploadFileData}
`;

export const GET_COMPANIES = gql`
  query GetCompanies {
    companies(pagination: {limit: -1}) {
      data {
        id
        attributes {
          key
          name
        }
      }
    }
  }
`;

export const GET_PLACES = gql`
  query GetPlaces {
    places(pagination: {limit: -1}) {
      data {
        id
        attributes {
          key
          name
        }
      }
    }
  }
`;

export const GET_ACTIVITY_FIELDS = gql`
  query GetActivityFields {
    fieldOfActivities(pagination: {limit: -1}) {
      data {
        id
        attributes {
          key
          name
        }
      }
    }
  }
`;

export const GET_EMPLOYMENT_TYPES = gql`
  query GetEmploymentTypes {
    typeOfEmployments(pagination: {limit: -1}) {
      data {
        id
        attributes {
          key
          name
        }
      }
    }
  }
`;

export const GET_EMPLOYMENT_TIMES = gql`
  query GetEmploymentTimes {
    employmentTimes(pagination: {limit: -1}) {
      data {
        id
        attributes {
          key
          name
        }
      }
    }
  }
`;

export const GET_TAGS = gql`
  query GetTags {
    tags(pagination: {limit: -1}) {
      data {
        id
        attributes {
          key
          name
          color
          icon
        }
      }
    }
  }
`;

export const SEARCH_OFFERS = gql`
  query SearchOffers($text: String!, $publicationState: PublicationState = LIVE) {
    offers(filters: {title: {containsi: $text}}, publicationState: $publicationState) {
      data {
        id
        attributes {
          publishedAt
          title
          locale
          company {
            data {
              id
              attributes {
                name
              }
            }
          }
          localizations(publicationState: PREVIEW) {
            data {
              id
              attributes {
                locale
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_OFFER_QUESTIONS = gql`
  query GetOfferQuestions($id: ID!) {
    offer(id: $id) {
      data {
        id
        attributes {
          title
          questions {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_QUESTIONS = gql`
  query GetQuestions {
    questions(pagination: {limit: -1}) {
      data {
        id
        attributes {
          name
          question
          answer {
            ... on ComponentAnswerYesNo {
              id
              preferred
            }
            ... on ComponentAnswerMultipleChoice {
              id
              onlyOneAnswer
              options {
                id
                answer
                preferred
              }
            }
            ... on ComponentAnswerFreeText {
              id
              required
            }
            ... on Error {
              code
            }
          }
        }
      }
    }
  }
`;

export const CREATE_QUESTION = gql`
  mutation CreateQuestion($data: QuestionInput!) {
    createQuestion(data: $data) {
      data {
        id
      }
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation DeleteQuestion($id: ID!) {
    deleteQuestion(id: $id) {
      data {
        id
      }
    }
  }
`;

export const CREATE_OFFER_LOCALIZATION = gql`
  mutation CreateOfferLocalization($id: ID, $data: OfferInput, $locale: I18NLocaleCode) {
    createOfferLocalization(
      id: $id
      data: $data
      locale: $locale
    ) {
      data {
        id
      }
    }
  }
`;

export const GET_OFFER_LOCALIZATIONS = gql`
  query GetOfferLocalizations($id: ID!) {
    offer(id: $id) {
      data {
        id
        attributes {
          locale
          slug
          localizations(publicationState: PREVIEW) {
            data {
              id
              attributes {
                locale
                slug
              }
            }
          }
        }
      }
    }
  }
`;

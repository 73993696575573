import React, { useState, useContext, createContext, useMemo, useCallback, useEffect } from 'react';
import client from '../../apollo/client';
import { STRAPI_URL } from '../../config';
import { GetUserQuery } from '../../generated/graphql-types';

type UserContextType = {
  isAuth: boolean;
  user: GetUserQuery['me'] | null,
  token: string | null;
  signin: (identifier: string, password: string) => Promise<any>,
  verify: (userId: number | string, code: number) => Promise<any>,
  sendCode: (userId: number | string, method?: 'email' | 'sms') => Promise<any>,
  signout: () => void
}

export const UserContext = createContext<UserContextType>({
  isAuth: false,
  user: null,
  token: null,
  signin: (identifier, password) => Promise.resolve(null),
  verify: (userId, code) => Promise.resolve(null),
  sendCode: (userId, method = 'email') => Promise.resolve(null),
  signout: () => { }
});

const { Provider } = UserContext;

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const auth: UserContextType = useProvideUser();
  return <Provider value={auth}> {children} </Provider>;
};

export const useUserContext = () => {
  return useContext(UserContext);
};

function useProvideUser() {
  const [token, setToken] = useState<string | null>(localStorage.getItem('ks-jwt') ?? null);
  const [user, setUser] = useState<GetUserQuery['me']>(null);

  const signin = useCallback(async (identifier: string, password: string) => {
    return fetch(`${STRAPI_URL}/api/auth/local`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ identifier, password })
    })
      .then(r => r.json());
  }, []);

  const sendCode = useCallback(async (userId: number | string, method: 'email' | 'sms' = 'email') => {
    return fetch(`${STRAPI_URL}/api/users-permissions/send-code`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, method })
    })
      .then(r => r.json());
  }, []);

  const verify = useCallback(async (userId: number | string, code: number) => {
    return fetch(`${STRAPI_URL}/api/users-permissions/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, code })
    })
      .then(r => r.json())
      .then((r) => {
        const { jwt } = r;
        if (jwt) {
          setToken(jwt);
          localStorage.setItem('ks-jwt', jwt);
        } else {
          setToken(null);
          signout();
        }
        return r;
      });
  }, []);

  const getUser = useCallback(() => {
    if (token) {
      fetch(`${STRAPI_URL}/api/users/me`, {
        headers: { authorization: `Bearer ${token}` }
      })
        .then(r => r.json())
        .then((r) => {
          if (!r.error) {
            setUser(r);
          } else {
            signout();
          }
        })
        .catch(() => {
          signout();
        });
    }
  }, [token]);

  const signout = useCallback(() => {
    setToken(null);
    if (typeof window !== 'undefined') localStorage.removeItem('ks-jwt');
    client.resetStore();
  }, []);

  const isAuth = useMemo(() => !!user, [user]);

  useEffect(() => {
    getUser();
  }, [token]);

  return useMemo(() => ({
    isAuth,
    user,
    token,
    signin,
    verify,
    sendCode,
    signout
  }), [
    isAuth,
    user,
    token,
    signin,
    verify,
    sendCode,
    signout
  ]);
}

import {
  Card as SCard,
  CardAction,
  CardAsset,
  CardHeader,
  CardBody,
  CardContent,
  CardBadge,
  CardTitle,
  CardSubtitle,
} from '@strapi/design-system/Card';
import { FC, useEffect, useMemo, useState } from 'react';
import File from '@strapi/icons/File';
import Trash from '@strapi/icons/Trash';
import { IconButton } from '@strapi/design-system/IconButton';

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}

export const CardFile: FC<{ file: File, onRemove?: () => void }> = ({ file, onRemove }) => {
  const [src, setSrc] = useState<string>();

  const isImage = useMemo(() => file.type && /image\//i.test(file.type), [file]);

  const size = useMemo(() => formatBytes(file.size), [file.size]);

  useEffect(() => {
    if (file) setSrc(URL.createObjectURL(file));

    return () => {
      if (src) URL.revokeObjectURL(src);
    }
  }, [file]);

  return <SCard>
    <CardHeader>
      <CardAction position="end">
        <IconButton label="Remove" icon={<Trash />} onClick={onRemove} />
      </CardAction>
      {isImage ? <CardAsset src={src} /> : <CardAsset><File fontSize={48} /></CardAsset>}
    </CardHeader>
    <CardBody>
      <CardContent>
        <CardTitle>{file.name}</CardTitle>
        <CardSubtitle>{file.type} - {size}</CardSubtitle>
      </CardContent>
      <CardBadge>{isImage ? 'Image' : 'Doc'}</CardBadge>
    </CardBody>
  </SCard>
};

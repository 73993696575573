import { Box } from '@strapi/design-system/Box';
import { Button } from '@strapi/design-system/Button';
import { Flex } from '@strapi/design-system/Flex';
import { BaseHeaderLayout } from '@strapi/design-system/Layout';
import { Stack } from '@strapi/design-system/Stack';
import { useCallback, useMemo } from 'react';
import { Navigate, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetOfferLocalizationsQuery, useGetOfferQuery } from '../../generated/graphql-types';
import { resetData, resetForm } from '../../store/features/offer/offerSlice';
import { setStep } from '../../store/features/steps/stepsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { LOCALE, STEP } from '../../types';
import { Select } from '../Inputs/Select';
import { Layout } from '../Layout';
import { OfferForm } from '../OfferForm';
import { Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8 } from '../OfferForm/Steps';

export const CreateOffer = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const forId = searchParams.get('for') ?? null;
  const locale = searchParams.get('locale') ?? LOCALE.DE;
  const copyId = searchParams.get('copy') ?? null;

  const step = useAppSelector(state => state.steps.currentStep);
  const dispatch = useAppDispatch();

  const { data: forOfferData } = useGetOfferLocalizationsQuery({
    variables: { id: forId ?? '' },
    skip: !forId
  });

  const { data: copyOfferData } = useGetOfferQuery({
    variables: { id: copyId ?? '' },
    skip: !copyId
  });

  const forOffer = useMemo(() => forOfferData?.offer?.data ?? null, [forOfferData]);

  const localeChange = useCallback((locale: LOCALE) => {
    if (locale === forOffer?.attributes?.locale && forOffer.id) {
      navigate(`/job/${forOffer.id}?locale=${locale}`);
    } else {
      setSearchParams({ locale, ...(forId && { for: forId }) });
    }
  }, [forOffer, forId]);

  const cancel = useCallback(() => {
    dispatch(setStep(STEP.FIRST));
    dispatch(resetForm());
    dispatch(resetData());
    navigate('/');
  }, []);

  return <Layout
    header={<BaseHeaderLayout
      primaryAction={<Stack spacing={2}>
        <Stack horizontal spacing={2} justifyContent="end">
          <Button size="L" variant="tertiary" onClick={cancel}>Abbrechen</Button>
          <Button
            as="label"
            htmlFor="save"
            size="L"
            variant="default"
            disabled={step !== STEP.LAST}
            style={{
              borderRadius: '4px',
              cursor: 'pointer',
              ...step !== STEP.LAST && {
                pointerEvents: 'none'
              }
          }}>
            Speichern
          </Button>
        </Stack>
        <Flex justifyContent="end">
          <Box width="150px">
            <Select
              name="locales"
              value={locale}
              onChange={v => localeChange(v)}
              isClearable={false}
              isDisabled={!forId}
              options={[
                { value: LOCALE.DE, label: "German (de)" },
                { value: LOCALE.EN, label: "English (en)" }
              ]}
              placeholder="Select locale"
            />
          </Box>
        </Flex>
      </Stack>}
      title="Neue Stellenanzeige" />}>
    <Routes>
      <Route path="/" element={<OfferForm key="new" offer={copyOfferData?.offer?.data?.attributes} />}>
        <Route path="1" element={<Step1 />} />
        <Route path="2" element={<Step2 />} />
        <Route path="3" element={<Step3 />} />
        <Route path="4" element={<Step4 />} />
        <Route path="5" element={<Step5 />} />
        <Route path="6" element={<Step6 />} />
        <Route path="7" element={<Step7 />} />
        <Route path="8" element={<Step8 />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  </Layout>;
};

import { SelectOption } from '../components/Inputs/Select';
import { ID, Maybe } from '../types';

export const getOptions = (data?: { id?: Maybe<ID>, attributes?: Maybe<Record<string, any>> }[], labelField = 'name', valueField?) => {
  return data
    ?.reduce(
      (arr, { id, attributes }) => id && attributes
        ? [...arr, { value: valueField ? attributes[valueField] : id, label: attributes[labelField], ...attributes }]
        : arr,
      [] as SelectOption[]
    ) ?? []
};

import {
  Card as SCard,
  CardHeader,
  CardBody,
  CardCheckbox,
  CardAsset,
  CardContent,
  CardBadge,
  CardTitle,
  CardSubtitle,
} from '@strapi/design-system/Card';
import { FC, useMemo } from 'react';
import { STRAPI_URL } from '../../../config';
import { UploadFileDataFragment } from '../../../generated/graphql-types';

export const Card: FC<{ file: UploadFileDataFragment, checked?: boolean, onChange?: (evt: any) => void }> = ({
  file,
  checked,
  onChange
}) => {
  const isImage = useMemo(() => file.ext && /\.(jp(e)g|png|svg)$/i.test(file.ext), [file]);

  return <SCard>
    <CardHeader>
      <CardCheckbox value={checked} onChange={onChange} />
      {/*<CardAction position="end">*/}
      {/*  <IconButton label="Edit the thing" icon={<Pencil />} />*/}
      {/*</CardAction>*/}
      <CardAsset src={`${STRAPI_URL}${file.formats?.thumbnail.url || file.url}`} />
    </CardHeader>
    <CardBody>
      <CardContent>
        <CardTitle>{file.name}</CardTitle>
        <CardSubtitle>{file.ext} - {file.width}X{file.height}</CardSubtitle>
      </CardContent>
      <CardBadge>{isImage ? 'Image' : 'Doc'}</CardBadge>
    </CardBody>
  </SCard>
};

import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@strapi/design-system/Box';
import { Divider } from '@strapi/design-system/Divider';
import { Typography } from '@strapi/design-system/Typography';
import { CodeForm } from './CodeForm';
import { LoginForm } from './LoginForm';
import styles from './Login.module.scss';

export const Login = () => {
  let navigate = useNavigate();
  let { state } = useLocation();
  const [userId, setUserId] = useState<number | string>('');

  const from = (state as any)?.from.pathname || '/';

  const onVerify = useCallback(() => {
    navigate(from, { replace: true })
  }, [from]);

  return <Box
    className={styles.Login}
    borderColor="neutral200"
    shadow="filterShadow"
    hasRadius={true}
    padding={5}>
    <Typography variant="alpha" as="h1">Anmeldung</Typography>
    <Divider />
    {!!userId
      ? <CodeForm userId={userId} onVerify={onVerify} />
      : <LoginForm setUserId={setUserId} />
    }
  </Box>;
};
import { Box } from '@strapi/design-system/Box';
import { Stack } from '@strapi/design-system/Stack';
import { Button } from '@strapi/design-system/Button';
import { Typography } from '@strapi/design-system/Typography';
import { FC, FormEventHandler, ReactNode } from 'react';

export const Step: FC<{
  title: string,
  onSubmit?: FormEventHandler<HTMLFormElement>,
  nextCallback?: () => void,
  prevCallback?: () => void,
  cleanCallback?: () => void,
  resetCallback?: () => void,
  isDirty?: boolean,
  submitText?: string,
  children: ReactNode
}> = ({
  title,
  onSubmit,
  nextCallback,
  prevCallback,
  cleanCallback,
  resetCallback,
  isDirty = true,
  submitText = 'Weiter',
  children
}) => {
  const content = <>
    {children}
    <Stack spacing={2} horizontal justifyContent="flex-end" marginTop={8}>
      {!!cleanCallback && <Button onClick={cleanCallback} variant="secondary" size="L">Clean</Button>}
      {!!resetCallback &&
        <Button onClick={resetCallback} variant="secondary" size="L" disabled={!isDirty}>Reset</Button>}
      {!!prevCallback && <Button variant="tertiary" size="L" onClick={prevCallback}>Zurück</Button>}
      <Button
        variant="default"
        size="L"
        {...!!onSubmit && { type: 'submit' }}
        {...!!nextCallback && { onClick: nextCallback }}
      >{submitText}</Button>
    </Stack>
  </>;

  return <Box background="neutral0" padding={5}>
    <Box marginBottom={4}>
      <Typography variant="beta" fontWeight="bold" as="h2">{title}</Typography>
    </Box>

    {!!onSubmit
      ? <form id="offer-form" onSubmit={onSubmit} noValidate>{content}</form>
      : <Box>{content}</Box>
    }
  </Box>;
};
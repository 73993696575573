import { Grid, GridItem } from '@strapi/design-system/Grid';
import { FC } from 'react';
import { Control, Controller, FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { getError } from '../../../helpers/errors';
import { CarouselInput } from '../../CarouselInput';
import { Input } from '../../Inputs/Input';
import { Textarea } from '../../Inputs/Textarea';
import { FillButton } from '../FillButton';

export const SEO: FC<{
  register: UseFormRegister<any>
  control: Control<any>
  errors: FieldErrors<any>
  setValue: UseFormSetValue<any>
}> = ({ register, control, errors, setValue }) => {
  return <GridItem col={12}>
    <Grid gap={5} background="neutral0">
      <GridItem col={6} s={12}>
        <Input
          label="Titel der Stellenanzeige"
          required
          error={errors.seo?.metaTitle?.message}
          {...register('seo.metaTitle', { required: getError('meta title') })}
          labelAction={<FillButton field="seo.metaTitle" setValue={setValue} />} />
      </GridItem>
      <GridItem col={6} s={12}>
        <Controller
          name="seo.metaDescription"
          control={control}
          render={({ field }) => <Textarea
            {...field} label="Kurzbeschreibung"
            labelAction={<FillButton field="seo.metaDescription" setValue={setValue} />} />} />
      </GridItem>
      <GridItem col={6} s={12}>
        <Input
          label="Bild-Beschreibung"
          required
          error={errors.seo?.sharedImage?.alt?.message}
          {...register('seo.sharedImage.alt', { required: getError('alt') })}
          labelAction={<FillButton field="seo.sharedImage.alt" setValue={setValue} />} />
      </GridItem>
      <GridItem col={6} s={12}>
        <Controller
          name="seo.sharedImage.media"
          control={control}
          rules={{ required: getError('media') }}
          render={({ field }) => (
            <CarouselInput
              {...field}
              label="Bild"
              multiple={false}
              error={errors.seo?.sharedImage?.media?.message}
              required
              labelAction={<FillButton field="seo.sharedImage.media" isRelation setValue={setValue} />} />
          )} />
      </GridItem>
    </Grid>
  </GridItem>;
}

import { Checkbox as SCheckbox } from '@strapi/design-system/Checkbox';
import { forwardRef } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

type CheckboxProps = {
  label?: string
} & ControllerRenderProps<any>

export const Checkbox = forwardRef<HTMLDivElement, CheckboxProps>(({
  label,
  onChange,
  ...rest
}, ref) => {
  return <div ref={ref} tabIndex={-1}>
    <SCheckbox
      {...rest}
      onChange={evt => onChange(evt.target.checked)}
    >{label}</SCheckbox>
  </div>
});

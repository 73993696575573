import { FC, useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { StepContext } from '../../../types';
import { Questions } from '../../Questions';
import { Step } from './step';

export const Step7: FC = () => {
  const { prev, save, submit } = useOutletContext<StepContext>();
  const questions = useAppSelector(state => state.offer.form.questions);
  const [newQuestions, setNewQuestions] = useState<string[]>(questions);

  const nextCallback = useCallback(() => {
    submit(({ questions: newQuestions }));
  }, [newQuestions]);

  const saveCallback = () => {
    save({ questions: newQuestions });
  };

  useEffect(() => {
    setNewQuestions(questions);
  }, [questions]);

  return <Step title="Bewerberfragen" prevCallback={prev} nextCallback={nextCallback}>
    <input id="save" hidden onClick={saveCallback} />
    <Questions questions={newQuestions} setQuestions={setNewQuestions} />
  </Step>;
};
import { Grid, GridItem } from '@strapi/design-system/Grid';
import { FC } from 'react';
import { Controller, Control, FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { getError } from '../../../helpers/errors';
import { CarouselInput } from '../../CarouselInput';
import { Input } from '../../Inputs/Input';
import { Textarea } from '../../Inputs/Textarea';
import { FillButton } from '../FillButton';

export const ContactPerson: FC<{
  register: UseFormRegister<any>
  control: Control<any>
  errors: FieldErrors<any>
  setValue: UseFormSetValue<any>
}> = ({ register, control, errors, setValue }) => {
  return <GridItem col={12}>
    <Grid gap={5} background="neutral0">
      <GridItem col={6} s={12}>
        <Input
          label="Name"
          required
          error={errors.contactPerson?.name?.message}
          {...register('contactPerson.name', { required: getError('name') })}
          labelAction={<FillButton field="contactPerson.name" setValue={setValue} />} />
      </GridItem>
      <GridItem col={6} s={12}>
        <Input
          label="Position"
          {...register('contactPerson.position')}
          labelAction={<FillButton field="contactPerson.position" setValue={setValue} />} />
      </GridItem>
      <GridItem col={6} s={12}>
        <Input
          label="E-Mail"
          type="email"
          error={errors.contactPerson?.email?.message}
          {...register('contactPerson.email', {
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: getError('email', 'pattern')
            }
          })}
          labelAction={<FillButton field="contactPerson.email" setValue={setValue} />} />
      </GridItem>
      <GridItem col={6} s={12}>
        <Input
          label="Telefon"
          type="tel"
          {...register('contactPerson.phone')}
          labelAction={<FillButton field="contactPerson.phone" setValue={setValue} />} />
      </GridItem>
      <GridItem col={6} s={12}>
        <Controller
          name="contactPerson.additional"
          control={control}
          render={({ field }) => <Textarea
            {...field} label="Weiter Informationen"
            labelAction={<FillButton field="contactPerson.additional" setValue={setValue} />} />} />
      </GridItem>
      <GridItem col={6} s={12}>
        <Controller
          name="contactPerson.image"
          control={control}
          render={({ field }) => <CarouselInput
            {...field} label="Bild Ansprechpartner" multiple={false}
            labelAction={<FillButton field="contactPerson.image" isRelation setValue={setValue} />} />} />
      </GridItem>
    </Grid>
  </GridItem>;
};

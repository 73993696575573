import { Field, FieldError, FieldHint, FieldLabel } from '@strapi/design-system/Field';
import { Stack } from '@strapi/design-system/Stack';
import { Box } from '@strapi/design-system/Box';
import { Flex } from '@strapi/design-system/Flex';
import Cross from '@strapi/icons/Cross';
import CarretDown from '@strapi/icons/CarretDown';
import classNames from 'classnames';
import { ComponentProps, forwardRef, useCallback, useMemo } from 'react';
import { ControllerRenderProps, Noop } from 'react-hook-form';
import ReactSelect, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import SvgIcon from '../SvgIcon';
import styles from './Select.module.scss';
import commonStyles from './Common.module.scss';

export type SelectOption = {
  value: string
  label: string
}

type SelectProps = ComponentProps<typeof ReactSelect> & {
  label?: string
  required?: boolean
  hint?: string
  error?: string
  labelAction?: JSX.Element
  onBlur?: Noop
} & Omit<ControllerRenderProps<any>, 'onBlur'>;

type SelectAsyncProps = ComponentProps<typeof AsyncSelect> & {
  label?: string
  required?: boolean
  hint?: string
  error?: string
  onBlur?: Noop
} & Omit<ControllerRenderProps<any>, 'onBlur'>;

const ClearIndicator = props => {
  const Component = components.ClearIndicator;

  return (
    <Component {...props}>
      <Box className={styles.IconBox} as="button" type="button">
        <Cross />
      </Box>
    </Component>
  );
};

const DropdownIndicator = () => {
  return <Box className={styles.CarretBox} as="button" type="button" paddingRight={3}>
    <CarretDown fontSize={8} />
  </Box>
};

const Option = (props) => {
  const Component = components.Option;
  const { data: { label, icon, color } } = props;

  return <Component {...props}>
    <Flex>
      {!!icon && <Box paddingRight={2}>
        <SvgIcon icon={{ name: icon }} />
      </Box>}
      <div className={classNames({ [styles.Label]: icon && color, [styles[color]]: color })}>{label}</div>
    </Flex>
  </Component>
};

const MultiValue = (props) => {
  const Component = components.MultiValue;
  const { data: { label, icon, color } } = props;

  return <Component {...props}>
    <Flex>
      {!!icon && <Box paddingRight={2}>
        <SvgIcon icon={{ name: icon }} />
      </Box>}
      <div className={classNames({ [styles.Label]: icon && color, [styles[color]]: color })}>{label}</div>
    </Flex>
  </Component>
};

const Empty = () => null;

export const Select = forwardRef<any, SelectProps>(({
  label,
  required = false,
  placeholder = 'Auswählen',
  isMulti = false,
  hint,
  error,
  options,
  isClearable = true,
  labelAction,
  value,
  onChange,
  ...rest
}, ref) => {
  const selected = useMemo(
    () => isMulti
      ? options?.filter(o => value?.includes((o as SelectOption).value))
      : options?.find(o => value === (o as SelectOption).value) ?? null,
    [value, options, isMulti]
  );

  const change = useCallback((data) => {
    if (isMulti) {
      onChange(data.map(e => e.value));
    } else {
      onChange(data?.value ?? null);
    }
  }, [value, onChange]);

  return <Field error={error} hint={hint}>
    <Stack spacing={1}>
      {!!label &&
        <FieldLabel
          className={commonStyles.Label} action={<Box paddingLeft={2}>{labelAction}</Box>}
          required={required}>{label}</FieldLabel>}
      <ReactSelect
        {...rest}
        className={classNames(styles.Select, { [styles.error]: !!error })}
        classNamePrefix="select"
        isMulti={isMulti}
        placeholder={placeholder}
        value={selected}
        onChange={change}
        options={options}
        isClearable={isClearable}
        components={{
          ClearIndicator,
          DropdownIndicator,
          IndicatorSeparator: null,
          Option,
          MultiValue
        }}
        ref={ref} />
      <FieldHint />
      <FieldError />
    </Stack>
  </Field>
});

export const SelectAsync = forwardRef<any, SelectAsyncProps>(({
  label,
  required = false,
  placeholder = 'Auswählen',
  hint,
  error,
  ...rest
}, ref) => {
  return <Field error={error} hint={hint}>
    <Stack spacing={1}>
      {!!label && <FieldLabel required={required}>{label}</FieldLabel>}
      <AsyncSelect
        {...rest}
        className={classNames(styles.Select, { [styles.error]: !!error })}
        classNamePrefix="select"
        placeholder={placeholder}
        components={{
          ClearIndicator,
          DropdownIndicator,
          IndicatorSeparator: null,
          Option,
          MultiValue: Empty
        }}
        ref={ref} />
      <FieldHint />
      <FieldError />
    </Stack>
  </Field>
});

import { Alert } from '@strapi/design-system/Alert';
import { Box } from '@strapi/design-system/Box';
import { FC, useCallback, useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  useCreateOfferLocalizationMutation,
  useCreateOfferMutation,
  useUpdateOfferMutation
} from '../../generated/graphql-types';
import { getDefaultValuesFromOffer } from '../../helpers/getDefaultValuesFromOffer';
import {
  resetData,
  setError,
  setLoading,
  updateData,
  updateDataAsync,
  updateForm
} from '../../store/features/offer/offerSlice';
import { nextStep, prevStep, setStep } from '../../store/features/steps/stepsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { INITIAL_FORM_DATA, LOCALE, OfferFormType, STEP } from '../../types';
import { Preloader } from '../Preloader';
import Progress from '../Progress';
import styles from './OfferForm.module.scss';

export const OfferForm: FC<{ offer?: OfferFormType, refetch?: () => void }> = ({ offer, refetch }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<'id'>();
  const [searchParams] = useSearchParams();
  const locale = searchParams.get('locale') ?? LOCALE.DE;
  const forId = searchParams.get('for') ?? null;
  const copyId = searchParams.get('copy') ?? null;
  const step = useAppSelector(state => state.steps.currentStep);
  const error = useAppSelector(state => state.offer.error);
  const loading = useAppSelector(state => state.offer.loading);
  const changes = useAppSelector(state => state.offer.data);
  const dispatch = useAppDispatch();
  const isSetForm = useRef(false);

  const [updateOffer] = useUpdateOfferMutation();
  const [createOffer] = useCreateOfferMutation();
  const [createOfferLocalization] = useCreateOfferLocalizationMutation();

  const prev = useCallback(() => dispatch(prevStep()), []);
  const next = useCallback(() => dispatch(nextStep()), []);

  const successCallback: any = useCallback(
    (field: 'createOfferLocalization' | 'createOffer') => ({ data }) => {
      if (data?.[field]?.data?.id) {
        dispatch(resetData());
        dispatch(setStep(0));
        navigate(`/job/${data[field].data.id}`);
      }
    },
    []
  );

  const errorCallback = useCallback((e) => {
    console.log(e);
    dispatch(setError(e));
  }, []);

  const finallyCallback = useCallback(() => dispatch(setLoading(false)), []);

  const save = useCallback((data) => {
    if (id) {
      dispatch(setLoading(true));
      dispatch(setError(''));
      dispatch(updateDataAsync(data))
        .then(data => {
          if (Object.keys(data).length === 0) {
            return;
          }
          updateOffer({ variables: { id, data, locale } })
            .then(() => {
              dispatch(resetData());
              refetch?.();
            })
            .catch(errorCallback)
            .finally(finallyCallback);
        })
    } else {
      if (step === STEP.LAST) {
        dispatch(setLoading(true));
        dispatch(setError(''));
        dispatch(updateDataAsync(data))
          .then(data => {
            if (Object.keys(data).length === 0) {
              return;
            }
            if (!!forId && locale) {
              createOfferLocalization({ variables: { id: forId, data, locale } })
                .then(successCallback('createOfferLocalization'))
                .catch(errorCallback)
                .finally(finallyCallback);
            } else {
              createOffer({ variables: { data, locale } })
                .then(successCallback('createOffer'))
                .catch(errorCallback)
                .finally(finallyCallback);
            }
          })
      } else {
        dispatch(updateData(data));
        dispatch(updateForm(data));
      }
    }
  }, [id, step]);

  const submit = useCallback((data) => {
    if (step === STEP.LAST) {
      dispatch(updateDataAsync(data))
        .then(data => {
          dispatch(setLoading(true));
          dispatch(setError(''));

          if (id) {
            updateOffer({ variables: { id, data } })
              .then(() => {
                dispatch(setStep(STEP.FIRST));
                dispatch(resetData());
              })
              .catch(errorCallback)
              .finally(finallyCallback);
          } else {
            if (!!forId && locale) {
              createOfferLocalization({ variables: { id: forId, data, locale } })
                .then(successCallback('createOfferLocalization'))
                .catch(errorCallback)
                .finally(finallyCallback);
            } else {
              createOffer({ variables: { data, locale } })
                .then(successCallback('createOffer'))
                .catch(errorCallback)
                .finally(finallyCallback);
            }
          }
        });
    } else {
      dispatch(updateData(data));
      dispatch(updateForm(data));
      next();
    }
  }, [step]);

  useEffect(() => {
    if (!isSetForm.current) {
      if ((id || copyId) && offer) {
        dispatch(updateForm({ ...getDefaultValuesFromOffer(offer), ...changes }));
        if (copyId) {
          dispatch(updateData({ ...getDefaultValuesFromOffer(offer) }));
        }
      } else {
        dispatch(updateForm({ ...INITIAL_FORM_DATA, ...changes }));
      }
      isSetForm.current = !(id || copyId);
    }
  }, [offer]);

  useEffect(() => {
    if (step !== 0) {
      if (id) {
        navigate(`/job/${id}/${step}${location.search ?? ''}`);
      } else {
        navigate(`/job/create/${step}${location.search ?? ''}`);
      }
    }
  }, [step]);

  useEffect(() => {
    if (step === 0) dispatch(setStep(STEP.FIRST));
  }, []);

  return <Box background="neutral0" padding={5} hasRadius shadow="tableShadow" className={styles.OfferForm}>
    <Progress value={step / STEP.LAST * 100} />
    {!!error && <Box padding={5}>
      <Alert onClose={() => dispatch(setError(''))} closeLabel="Close alert" title="" variant="danger">
        <pre>{
          error.graphQLErrors.map(err => err.extensions.error.details.errors.map(e => `${e.message} (path: ${e.path.join('.')})`)) ||
          error.message
        }</pre>
      </Alert>
    </Box>}
    {loading && <Preloader />}
    <Outlet context={{ prev, next, save, submit }} />
  </Box>;
};

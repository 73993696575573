import { Field, FieldError, FieldHint, FieldInput, FieldLabel } from '@strapi/design-system/Field';
import { Stack } from '@strapi/design-system/Stack';
import { Box } from '@strapi/design-system/Box';
import { forwardRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './Common.module.scss';

type InputProps = {
  type?: string
  label?: string
  step?: number
  hint?: string
  error?: string
  action?: JSX.Element
  labelAction?: JSX.Element
} & UseFormRegisterReturn;

export const Input = forwardRef<HTMLElement, InputProps>(({
  type = 'text',
  step = 1,
  label,
  required,
  hint,
  error,
  action,
  labelAction,
  ...rest
}, ref) => {
  return <Field error={error} hint={hint}>
    <Stack spacing={1}>
      <FieldLabel className={styles.Label} action={<Box paddingLeft={2}>{labelAction}</Box>} required={required}>{label}</FieldLabel>
      <FieldInput
        {...rest}
        required={required}
        type={type}
        ref={ref}
        endAction={action}
        {...!isNaN(step) && { step }} />
      <FieldHint />
      <FieldError />
    </Stack>
  </Field>;
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STEP } from '../../../types';

interface StepsState {
  currentStep: STEP | 0
}

const initialState: StepsState = {
  currentStep: STEP.NAME
};

export const offerSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    prevStep: (state) => {
      state.currentStep = Math.max(STEP.FIRST, state.currentStep - 1);
    },
    nextStep: (state) => {
      state.currentStep = Math.min(STEP.LAST, state.currentStep + 1);
    },
    setStep: (state, action: PayloadAction<STEP>) => {
      state.currentStep = action.payload;
    }
  }
});

export const { prevStep, nextStep, setStep } = offerSlice.actions;

export default offerSlice.reducer;

import { Box } from '@strapi/design-system/Box';
import { Typography } from '@strapi/design-system/Typography';
import { FC } from 'react';
import { Maybe, QuestionAnswerDynamicZone } from '../../../generated/graphql-types';

export const Answer: FC<{ answer: Maybe<QuestionAnswerDynamicZone> }> = ({ answer }) => {
  if (answer?.__typename === 'ComponentAnswerYesNo') {
    return <Typography variant="omega">
      Yes / No {answer.preferred !== 'none' &&
      <Typography variant="pi" fontWeight="bold">({answer.preferred} - preferred)</Typography>}
    </Typography>;
  }
  if (answer?.__typename === 'ComponentAnswerFreeText') {
    return <Typography variant="omega">Free Text {answer.required &&
      <Typography variant="pi" fontWeight="bold">(required)</Typography>}</Typography>;
  }
  if (answer?.__typename === 'ComponentAnswerMultipleChoice') {
    return <>
      {answer.options.map(option => option
        ? <Box padding={2} key={option.id}>
          <Typography variant="omega">{option?.answer}</Typography>
          {option.preferred && <Typography variant="pi" fontWeight="bold"> - preferred</Typography>}
        </Box>
        : null)}
    </>;
  }
  return null;
}

import { forwardRef } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { ToggleInput } from '@strapi/design-system/ToggleInput';
import { Box } from '@strapi/design-system/Box';
import styles from './Common.module.scss';

type ToggleProps = {
  label?: string
  required?: string
  hint?: string
  error?: string
  labelAction?: JSX.Element
} & ControllerRenderProps<any>;

export const Toggle = forwardRef<HTMLDivElement, ToggleProps>(({
  label,
  required,
  hint,
  error,
  labelAction,
  value,
  onChange,
  ...rest
}, ref) => {
  return <div className={styles.Toggle} ref={ref} tabIndex={-1}>
    <ToggleInput
      {...rest}
      label={label}
      labelAction={<Box paddingLeft={2}>{labelAction}</Box>}
      required={required}
      hint={hint}
      error={error}
      onLabel="True"
      offLabel="False"
      checked={value}
      onChange={() => onChange(!value)} />
  </div>;
});

export const getTranslation = (str?: string | null) => {
  const translations = {
    de: 'German (de)',
    en: 'English (en)',
    company: 'Unternehmen',
    place: 'Arbeitsort',
    field_of_activities: 'Art der Anstellung',
    type_of_employments: 'Tätigkeitenbereich',
    employment_times: 'Arbeitszeit'
  }
  return str ? translations[str] ?? '' : '';
};
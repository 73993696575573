import { ID, Maybe, OfferFormType, OfferState } from '../types';

const withoutTypename = (data) => {
  if (data) {
    const { __typename, ...res } = data;
    return res;
  }
  return data;
};

const getId = (data?: Maybe<{ id?: Maybe<ID> }>, defaultValue: any = null) => {
  return data?.id ?? defaultValue;
};

const getIds = (data?: { id?: Maybe<ID> }[]) => {
  return data?.map(({ id }) => id) as string[] ?? [];
};

export const getDefaultValuesFromOffer: (offer: NonNullable<OfferFormType>) => OfferState = (offer) => {
  const {
    title,
    slug,
    short_description,
    description,
    mainImage,
    images,
    company,
    place,
    field_of_activities,
    type_of_employments,
    employment_times,
    benefits,
    location,
    contactPerson: {
      name,
      position,
      email,
      phone,
      additional,
      image
    },
    seo: {
      metaTitle,
      metaDescription,
      sharedImage: {
        media,
        alt
      },
      keywords,
      preventIndexing
    },
    withoutCV,
    remote,
    tags,
    relatedOffers,
    videoURL,
    priority,
    questions
  } = offer;
  return {
    title,
    slug,
    short_description: short_description ?? '',
    description: description ?? '',
    mainImage: getId(mainImage?.data),
    images: getIds(images?.data),
    company: getId(company?.data),
    place: getId(place?.data),
    field_of_activities: getIds(field_of_activities?.data),
    type_of_employments: getIds(type_of_employments?.data),
    employment_times: getIds(employment_times?.data),
    benefits: benefits?.reduce(
      (arr, b) => b
        ? [...arr, { text: b.text, icon: b.icon?.data?.id ?? null, description: b.description ?? '' }]
        : arr,
      [] as OfferState['benefits']
    ) ?? [],
    location: !!location ? {
      address: withoutTypename(location.address) ?? null,
      coordinates: withoutTypename(location.coordinates) ?? null
    } : null,
    contactPerson: {
      name,
      position: position ?? '',
      email: email ?? '',
      phone: phone ?? '',
      additional: additional ?? '',
      image: getId(image?.data)
    },
    seo: {
      metaTitle,
      metaDescription: metaDescription ?? '',
      sharedImage: {
        media: getId(media.data),
        alt
      },
      keywords: keywords ?? '',
      preventIndexing: preventIndexing ?? false
    },
    withoutCV: withoutCV ?? false,
    remote: remote ?? null,
    tags: getIds(tags?.data),
    relatedOffers: getIds(relatedOffers?.data),
    videoURL: videoURL ?? '',
    priority: priority ?? 0,
    questions: getIds(questions?.data)
  };
};
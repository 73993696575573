import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_FORM_DATA, OfferState as Offer } from '../../../types';
import { RootState } from '../../store';

interface OfferState {
  form: Offer
  data: Partial<Offer>
  loading: boolean
  error?: any
}

const initialState: OfferState = {
  form: INITIAL_FORM_DATA,
  data: {},
  loading: false,
  error: undefined
};

export const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    updateForm: (state, action: PayloadAction<Partial<Offer>>) => {
      state.form = { ...state.form, ...action.payload };
    },
    resetForm: (state) => {
      state.form = { ...initialState.form };
    },
    updateData: (state, action: PayloadAction<Partial<Offer>>) => {
      state.data = { ...state.data, ...action.payload };
    },
    resetData: (state) => {
      state.data = {};
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    }
  }
});

export const updateDataAsync = (payload: Partial<Offer>) => (dispatch, getState) => {
  dispatch(updateData(payload));
  return Promise.resolve((getState() as RootState).offer.data);
};

export const { updateForm, resetForm, updateData, resetData, setLoading, setError } = offerSlice.actions;

export default offerSlice.reducer;

import {
  Enum_Componentmainaddress_Country,
  Enum_Offer_Remote,
  GetOfferQuery
} from '../generated/graphql-types';

export type Maybe<T> = T | null;
export type ID = string;

type Benefit = {
  description: string;
  icon: Maybe<ID>;
  text: string;
}

type ContactPerson = {
  additional: string;
  email: string;
  image: Maybe<ID>;
  name: string;
  phone: string;
  position: string;
}

type Address = {
  country: Enum_Componentmainaddress_Country;
  locality: string;
  postalCode: string;
  region: string;
  street: string;
}

type Coordinates = {
  lat: Maybe<number>;
  long: Maybe<number>;
}

type Location = {
  address: Maybe<Address>;
  coordinates: Maybe<Coordinates>;
}

type SharedImage = {
  alt: string;
  media: Maybe<ID>;
}

type Seo = {
  metaDescription: string;
  metaTitle: string;
  sharedImage: SharedImage;
}

export type OfferState = {
  benefits: Benefit[];
  company: Maybe<ID>;
  contactPerson: ContactPerson;
  description: string;
  employment_times: ID[];
  field_of_activities: ID[];
  images: ID[];
  location: Maybe<Location>;
  mainImage: Maybe<ID>;
  place: Maybe<ID>;
  priority: number;
  questions: ID[];
  relatedOffers: ID[];
  remote: Maybe<Enum_Offer_Remote>;
  seo: Seo;
  short_description: string;
  slug: string;
  tags: ID[];
  title: string;
  type_of_employments: ID[];
  videoURL: string;
  withoutCV: boolean;
};

export const enum LOCALE {
  DE = 'de',
  EN = 'en'
}

export const enum STEP {
  NAME = 1,
  DESCRIPTION,
  MEDIA,
  CONTACT,
  SEO,
  LOCATION,
  QUESTIONS,
  OTHER,
  FIRST = 1,
  LAST = 8
}

export type StepContext = {
  prev: () => void
  next: () => void
  save: (data: Partial<OfferState>) => void
  submit: (data: Partial<OfferState>) => void
}

export const INITIAL_FORM_DATA: OfferState = {
  benefits: [],
  company: null,
  contactPerson: {
    name: '',
    position: '',
    email: '',
    phone: '',
    additional: '',
    image: null
  },
  description: '',
  employment_times: [],
  field_of_activities: [],
  images: [],
  location: null,
  mainImage: null,
  place: null,
  priority: 0,
  questions: [],
  relatedOffers: [],
  remote: null,
  seo: {
    metaTitle: '',
    metaDescription: '',
    sharedImage: {
      media: null,
      alt: ''
    }
  },
  short_description: '',
  slug: '',
  tags: [],
  title: '',
  type_of_employments: [],
  videoURL: '',
  withoutCV: false
};

export type OfferFormType = NonNullable<NonNullable<GetOfferQuery['offer']>['data']>['attributes'];

export type FilterField = 'company' | 'place' | 'field_of_activities' | 'type_of_employments' | 'employment_times';
export type FilterState = Partial<Record<FilterField, { keys: string[], labels: string[] }>>;
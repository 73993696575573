import { Enum_Tag_Icon } from '../../generated/graphql-types';

const svgSprite = '/svg-sprite.svg';

export type IconName = Enum_Tag_Icon;

type IconType = {
  name: IconName;
  size?: number;
  color?: string;
};

export default function SvgIcon({ icon, classes }: {
  icon: IconType
  classes?: string
}) {
  const { name, size, color } = icon;

  return (
    <svg
      width={size ?? 20}
      height={size ?? 20}
      fill={color ?? '#000000'}
      role="presentation"
      className={classes}
    >
      <use xlinkHref={svgSprite + '#i-' + name} />
    </svg>
  )
}

import { Accordion, AccordionContent, AccordionGroup, AccordionToggle } from '@strapi/design-system/Accordion';
import { Box } from '@strapi/design-system/Box';
import { Stack } from '@strapi/design-system/Stack';
import { Typography } from '@strapi/design-system/Typography';
import { IconButton } from '@strapi/design-system/IconButton';
import Trash from '@strapi/icons/Trash';
import Plus from '@strapi/icons/Plus';
import { FC } from 'react';
import { QuestionEntity } from '../../../generated/graphql-types';
import { Answer } from '../Answer';

export const List: FC<{
  label?: string
  questions: QuestionEntity[]
  index: number | null
  setIndex: (v: number | null) => void
  add?: (id: string) => void
  remove?: (id: string) => void
  emptyText?: string
}> = ({ label, questions, index, setIndex, add, remove, emptyText }) => {
  return <Stack spacing={5}>
    <Typography variant="delta">{label} ({questions.length})</Typography>
    {questions.length
      ? <AccordionGroup>
        {questions.map(({ id, attributes }, i) => id && attributes
          ? <Accordion
            key={id}
            expanded={i === index}
            onToggle={() => setIndex(i !== index ? i : null)}
            id={`cq-${index}`} size="S">
            <AccordionToggle
              action={<Stack horizontal spacing={1}>
                {!!add && <IconButton noBorder onClick={() => add(id)} label="Add" icon={<Plus />} />}
                {!!remove && <IconButton noBorder onClick={() => remove(id)} label="Delete" icon={<Trash />} />}
              </Stack>}
              title={attributes.question}
              togglePosition="left" />
            <AccordionContent>
              <Box padding={5}>
                {attributes.answer.map(((answer, index) =>
                    <Answer key={index} answer={answer} />
                ))}
              </Box>
            </AccordionContent>
          </Accordion>
          : null)}
      </AccordionGroup>
      : <Box background="neutral100" padding={5}>{emptyText}</Box>}
  </Stack>;
};

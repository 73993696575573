import { Grid, GridItem } from '@strapi/design-system/Grid';
import { FC, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { getChangedFields } from '../../../helpers/getChangedFields';
import { useAppSelector } from '../../../store/hooks';
import { StepContext } from '../../../types';
import { Textarea } from '../../Inputs/Textarea';
import { TextEditor } from '../../TextEditor';
import { FillButton } from '../FillButton';
import { Step } from './step';

export const Step2: FC = () => {
  const { prev, save, submit } = useOutletContext<StepContext>();
  const {
    short_description,
    description
  } = useAppSelector(state => state.offer.form);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
    formState: { isDirty, dirtyFields }
  } = useForm({
    reValidateMode: 'onBlur',
    defaultValues: {
      short_description,
      description
    }
  });

  const cleanCallback = useCallback(() => {
    reset({
      short_description: '',
      description: ''
    }, { keepDirty: true });
  }, []);

  const resetCallback = useCallback(() => {
    reset({
      short_description,
      description
    });
  }, []);

  const onSubmit = handleSubmit((fields) => {
    submit(getChangedFields(fields, dirtyFields));
  });

  const saveCallback = () => {
    trigger().then((isValid) => {
      if (isValid) save(getChangedFields(getValues(), dirtyFields));
    });
  };

  useEffect(() => {
    reset({
      short_description,
      description
    });
  }, [
    short_description,
    description
  ]);

  return <Step
    title="Stellenbeschreibung"
    onSubmit={onSubmit}
    prevCallback={prev}
    cleanCallback={cleanCallback}
    resetCallback={resetCallback}
    isDirty={isDirty}>
    <input id="save" hidden onClick={saveCallback} />
    <Grid gap={5}>
      <GridItem col={6} s={12}>
        <Controller
          name="short_description"
          control={control}
          render={({ field }) => <Textarea
            {...field} label="Kurzbeschreibung"
            required
            labelAction={<FillButton field="short_description" setValue={setValue} />} />} />
      </GridItem>
      <GridItem col={12}>
        <Controller
          name="description"
          control={control}
          render={({ field }) => <TextEditor
            {...field} label="Stellenbeschreibung"
            required
            labelAction={<FillButton field="description" setValue={setValue} />} />} />
      </GridItem>
    </Grid>
  </Step>;
};
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { Box } from '@strapi/design-system/Box';
import styles from './Layout.module.scss';
import utilStyles from '../../styles/Utility.module.scss';

export const Layout: FC<{ header?: ReactNode, children: ReactNode }> = ({ header = null, children }) => {
  return <div className={styles.Layout}>
    {header && <header className={classNames(styles.LayoutHeader, utilStyles.Container)}>{header}</header>}
    <main className={classNames(styles.LayoutContainer, utilStyles.Container)}>
      <Box className={styles.LayoutContent} paddingTop={5} paddingBottom={5}>
        {children}
      </Box>
    </main>
  </div>
};

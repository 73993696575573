import { Box } from '@strapi/design-system/Box';
import { FieldLabel } from '@strapi/design-system/Field';
import { Flex } from '@strapi/design-system/Flex';
import { Grid, GridItem } from '@strapi/design-system/Grid';
import { Stack } from '@strapi/design-system/Stack';
import { TextButton } from '@strapi/design-system/TextButton';
import { IconButton } from '@strapi/design-system/IconButton';
import { Accordion, AccordionToggle, AccordionContent, AccordionGroup } from '@strapi/design-system/Accordion';
import Plus from '@strapi/icons/Plus';
import Trash from '@strapi/icons/Trash';
import { FC, useCallback, useState } from 'react';
import { Controller, Control, FieldErrors, UseFormRegister, useWatch, UseFormSetValue } from 'react-hook-form';
import { getError } from '../../../helpers/errors';
import { OfferState } from '../../../types';
import { CarouselInput } from '../../CarouselInput';
import { Confirmation } from '../../Confirmation';
import { Input } from '../../Inputs/Input';
import { AddButton } from '../AddButton';
import { FillButton } from '../FillButton';

const NEW_BENEFIT: OfferState['benefits'][0] = {
  text: '',
  icon: null,
  description: ''
}

const Benefit: FC<{
  index: number
  register: UseFormRegister<any>
  control: Control<any>
  errors: FieldErrors<any>
}> = ({ index, register, control, errors }) => {
  return <Grid padding={5} gap={5}>
    <GridItem col={6} s={12}>
      <Stack spacing={5}>
        <Input
          label="Text"
          required
          error={errors.benefits?.[index]?.text?.message}
          {...register(`benefits.${index}.text`, { required: getError('text') })} />
        <Input
          label="Description"
          {...register(`benefits.${index}.description`)} />
      </Stack>
    </GridItem>
    <GridItem col={6} s={12}>
      <Controller
        key={index}
        name={`benefits.${index}.icon`}
        control={control}
        render={({ field }) => <CarouselInput {...field} label="Icon" multiple={false} />}
      />
    </GridItem>
  </Grid>;
};

export const Benefits: FC<{
  register: UseFormRegister<any>
  control: Control<any>
  errors: FieldErrors<any>
  setValue: UseFormSetValue<any>
}> = ({ register, control, errors, setValue }) => {
  const { benefits } = useWatch({ control });
  const [toRemove, setToRemove] = useState(false);

  const [openedIndex, setOpenedIndex] = useState<number | null>(null);

  const toggleOpened = useCallback((index) => {
    setOpenedIndex(index !== openedIndex ? index : null)
  }, [openedIndex]);

  const add = useCallback(() => {
    setValue('benefits', [...(benefits ?? []), NEW_BENEFIT] as OfferState['benefits'], { shouldDirty: true });
    setOpenedIndex(benefits?.length ? benefits.length : 0);
  }, [benefits]);

  const remove = useCallback((index) => {
    if (benefits?.length) {
      const newBenefits = [...benefits];
      newBenefits.splice(index, 1);
      setValue('benefits', newBenefits as OfferState['benefits'], { shouldDirty: true });
    }
  }, [benefits]);

  const removeAll = useCallback(() => {
    setValue('benefits', [], { shouldDirty: true });
    setToRemove(false);
  }, []);

  const handle = useCallback((value) => value.map(v => ({
    text: v.text,
    description: v.description ?? '',
    icon: v.icon?.data?.id ?? null
  })), []);

  return <GridItem col={12}>
    <Stack spacing={1}>
      <Flex justifyContent="space-between">
        <FieldLabel
          action={<Box paddingLeft={2}><FillButton
            field="benefits"
            subquery="text description icon{data{id}}"
            handle={handle}
            setValue={setValue} /></Box>}>
          Vorteile ({benefits?.length ?? 0})</FieldLabel>
        <Stack horizontal spacing={2}>
          {!!benefits?.length &&
            <IconButton onClick={() => setToRemove(true)} noBorder label="Delete" icon={<Trash />} />}
        </Stack>
      </Flex>
      <Box background="neutral100" borderWidth="1px" borderColor="neutral200" borderStyle="solid" hasRadius>
        {benefits?.length
          ? <AccordionGroup
            error={!!errors.benefits ? getError('benefits', 'contain') : ''}
            footer={<Flex justifyContent="center" height="48px" background="neutral150">
              <TextButton onClick={add} startIcon={<Plus />}>
                Add an benefit
              </TextButton>
            </Flex>}>
            {benefits.map((benefit, index) => (
              <Accordion
                key={index}
                error={!!errors.benefits?.[index] ? getError('benefits', 'contain') : ''}
                expanded={openedIndex === index}
                onToggle={() => toggleOpened(index)}
                id={`benefit-${index}`}
                size="S">
                <AccordionToggle
                  action={<Stack horizontal spacing={0}>
                    <IconButton noBorder onClick={() => remove(index)} label="Delete" icon={<Trash />} />
                  </Stack>}
                  title={benefits?.[index]?.text ?? ''}
                  togglePosition="left" />
                <AccordionContent>
                  <Benefit
                    index={index}
                    register={register}
                    control={control}
                    errors={errors} />
                </AccordionContent>
              </Accordion>
            ))}
          </AccordionGroup>
          : <AddButton type="" onClick={add} />}
      </Box>
    </Stack>
    <Confirmation
      isShow={toRemove}
      setIsShow={setToRemove}
      onConfirm={removeAll} />
  </GridItem>;
};

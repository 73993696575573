import { Badge } from '@strapi/design-system/Badge';
import { Stack } from '@strapi/design-system/Stack';
import { IconButton } from '@strapi/design-system/IconButton';
import { Td, Tr } from '@strapi/design-system/Table';
import { Typography } from '@strapi/design-system/Typography';
import Pencil from '@strapi/icons/Pencil';
import Trash from '@strapi/icons/Trash';
import Duplicate from '@strapi/icons/Duplicate';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteOfferMutation } from '../../../generated/graphql-types';
import { getTranslation } from '../../../helpers/getTranslation';
import { OfferEntityPart } from '../../../queries/types';
import { LOCALE } from '../../../types';
import { Confirmation } from '../../Confirmation';
import styles from './Row.module.scss';

export const Row: FC<{
  offer: OfferEntityPart,
  setPreloader: (value: boolean) => void,
  setError: (value: string) => void,
  refetch?: () => void
}> = ({
  offer: {
    id,
    attributes,
  },
  setPreloader,
  setError
}) => {
  const navigate = useNavigate();
  const [isShowDialog, setIsShowDialog] = useState(false);

  const [deleteOffer] = useDeleteOfferMutation();

  const isPublished = useMemo(() => !!attributes?.publishedAt, [attributes]);

  const edit = useCallback((evt) => {
    evt.stopPropagation();
    if (id) navigate(`/job/${id}/1`);
  }, [navigate, id]);

  const copy = useCallback((evt) => {
    evt.stopPropagation();
    if (id) navigate(`/job/create?copy=${id}`);
  }, [navigate, id]);

  const remove = useCallback((evt) => {
    evt.stopPropagation();
    if (id) setIsShowDialog(true);
  }, [id]);

  const confirm = useCallback(() => {
    setIsShowDialog(false);
    if (id) {
      setPreloader(true);
      deleteOffer({ variables: { id } })
        .then(({ data }) => {
          if (data?.deleteOffer?.data?.id) navigate(0);
        })
        .catch(e => {
          console.log(e);
          setError(e.toString());
        })
        .finally(() => setPreloader(false));
    }
  }, [id]);

  const localeStr = useMemo(() => [
      getTranslation(attributes?.locale),
      ...attributes?.localizations?.data.map(l => getTranslation(l.attributes?.locale)) ?? []
    ].join(', '),
    [attributes]);

  return id && attributes
    ? <>
      <Tr className={styles.Row} onClick={edit}>
        <Td>
          <Typography textColor="neutral800">{attributes.title}</Typography>
        </Td>
        <Td>
          <Typography textColor="neutral800">{attributes.company?.data?.attributes?.name}</Typography>
        </Td>
        <Td>
          <Typography textColor="neutral800">{localeStr}</Typography>
        </Td>
        <Td>
          <Badge
            backgroundColor={isPublished ? 'success100' : 'secondary100'}
          >{isPublished ? 'Veröffentlicht' : 'Entwurf'}</Badge>
        </Td>
        <Td>
          <Stack horizontal spacing={1}>
            <IconButton onClick={edit} label="Edit" noBorder icon={<Pencil />} />
            {attributes?.locale === LOCALE.DE &&
              <IconButton onClick={copy} label="Copy" noBorder icon={<Duplicate />} />}
            <IconButton onClick={remove} label="Delete" noBorder icon={<Trash />} />
          </Stack>
        </Td>
      </Tr>
      <Confirmation
        message="Möchten Sie wirklich die Stellenanzeige löschen?"
        isShow={isShowDialog}
        setIsShow={setIsShowDialog}
        onConfirm={confirm} />
    </>
    : null;
};

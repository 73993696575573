import styles from './progress.module.scss';

export default function Progress({ value }) {
  return (
    <div className={styles.progress}>
      <div className={styles.progressOuter}>
        <div className={styles.progressInner} style={{ width: `${value}%` }} />
      </div>
    </div>
  );
}

import { Alert } from '@strapi/design-system/Alert';
import { Box } from '@strapi/design-system/Box';
import { Button } from '@strapi/design-system/Button';
import {
  Field,
  FieldAction,
  FieldError,
  FieldHint,
  FieldInput,
  FieldLabel
} from '@strapi/design-system/Field';
import { Stack } from '@strapi/design-system/Stack';
import Eye from '@strapi/icons/Eye';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useUserContext } from '../../contexts/userContext/userContext';

export const LoginForm = ({ setUserId }) => {
  const { signin } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [fieldType, setFieldType] = useState<'text' | 'password'>('password');

  const { control, handleSubmit, watch, formState: { errors } } = useForm({
    reValidateMode: 'onBlur',
    defaultValues: {
      identifier: '',
      password: ''
    }
  });

  const { identifier, password } = watch();

  useEffect(() => {
    if (error) setError('');
  }, [identifier, password]);

  const login = ({ identifier, password }) => {
    setLoading(true);
    signin(identifier, password)
      .then(({ userId, error }) => {
        setUserId(userId);
        if (error) setError(error.message);
      })
      .catch(e => {
        setError(e.toString());
      })
      .finally(() => setLoading(false));
  };

  const validate = (identifier) => {
    if (/@/.test(identifier)) {
      return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(identifier) || 'Das Format der E-Mail-Adresse ist falsch';
    }
    return true;
  };

  return <form onSubmit={handleSubmit(login)} noValidate>
    <Box paddingTop={5} paddingBottom={3}>
      <Controller
        name="identifier"
        control={control}
        rules={{
          required: "E-Mail-Adresse oder Benutzername eingeben",
          validate
        }}
        render={({ field }) => <Field error={errors.identifier?.message}>
          <Stack spacing={1}>
            <FieldLabel required>E-Mail oder Benutzername</FieldLabel>
            <FieldInput {...field} type="text" placeholder="E-Mail eingeben" />
            <FieldHint />
            <FieldError />
          </Stack>
        </Field>} />
    </Box>
    <Box paddingBottom={5}>
      <Controller
        name="password"
        control={control}
        rules={{
          required: "Passwort eingeben"
        }}
        render={({ field }) => <Field error={errors.password?.message}>
          <Stack spacing={1}>
            <FieldLabel required>Passwort</FieldLabel>
            <FieldInput {...field} type={fieldType} placeholder="Passwort eingeben" endAction={
              <FieldAction
                label="Show password"
                onMouseDown={() => setFieldType('text')}
                onMouseUp={() => setFieldType('password')}
              >
                <Eye fontSize={20} />
              </FieldAction>
            } />
            <FieldHint />
            <FieldError />
          </Stack>
        </Field>} />
    </Box>
    <Button type="submit" size="L" variant="success" disabled={loading} fullWidth>Weiter</Button>
    {!!error && <Box paddingTop={5}>
      <Alert
        onClose={() => setError('')} closeLabel="Close alert" title=""
        variant="danger">{error}</Alert>
    </Box>}
  </form>
};
const ERRORS = {
  required: "# is required",
  unique: "# must be unique",
  contain: "# contain error(s)",
  pattern: "wrong value for #"
};

export const getError: (name: string, type?: keyof typeof ERRORS) => string = (name, type = 'required') => {
  return ERRORS[type].replace(/#/, name);
};

export const getInnerValue = (data, path) => {
  const inner = (data, paths) => {
    if (paths.length > 1) {
      return inner(data[paths[0]], paths.slice(1));
    } else {
      return data?.[paths[0]];
    }
  };
  const paths = path.split('.');
  return inner(data, paths);
};

import { Alert } from '@strapi/design-system/Alert';
import { Box } from '@strapi/design-system/Box';
import { IconButton } from '@strapi/design-system/IconButton';
import { Typography } from '@strapi/design-system/Typography';
import Trash from '@strapi/icons/Trash';
import { FC, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Enum_Componentansweryesno_Preferred, useCreateQuestionMutation } from '../../../generated/graphql-types';
import { getError } from '../../../helpers/errors';
import { getQuestionName } from '../../../helpers/slugify';
import { FreeTextAnswerType, MultipleChoiceAnswerType, YesNoAnswerType } from '../../../queries/types';
import { Checkbox } from '../../Inputs/Checkbox';
import { Input } from '../../Inputs/Input';
import { Select } from '../../Inputs/Select';
import { FieldLabel } from '@strapi/design-system/Field';
import { Stack } from '@strapi/design-system/Stack';
import { Flex } from '@strapi/design-system/Flex';
import { Button } from '@strapi/design-system/Button';
import { Grid, GridItem } from '@strapi/design-system/Grid';
import { Toggle } from '../../Inputs/Toggle';
import { AddButton } from '../../OfferForm/AddButton';
import { Preloader } from '../../Preloader';

const NEW_YES_NO_ANSWER: YesNoAnswerType = {
  __typename: 'ComponentAnswerYesNo',
  preferred: 'none' as Enum_Componentansweryesno_Preferred
}

const NEW_FREE_TEXT_ANSWER: FreeTextAnswerType = {
  __typename: 'ComponentAnswerFreeText',
  required: false
}

const NEW_MULTIPLE_CHOICE_ANSWER: MultipleChoiceAnswerType = {
  __typename: 'ComponentAnswerMultipleChoice',
  onlyOneAnswer: false,
  options: [
    { answer: '', preferred: false },
    { answer: '', preferred: false }
  ]
}

type QuestionFormState = {
  question: string
  answer: [YesNoAnswerType | MultipleChoiceAnswerType | FreeTextAnswerType] | null
}

export const NewQuestion: FC<{ label?: string, add: (id: string) => void }> = ({ label, add: addToOffer }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [createQuestion] = useCreateQuestionMutation({ notifyOnNetworkStatusChange: true });

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm<QuestionFormState>({
    reValidateMode: 'onBlur',
    defaultValues: {
      question: '',
      answer: null
    }
  });

  const answer = watch('answer');

  const onSubmit = handleSubmit(async (fields) => {
    setError('');

    if (!answer) {
      setError('Answer is required');
      return;
    }

    const name = await getQuestionName(fields.question);

    setLoading(true);
    createQuestion({
      variables: {
        data: { ...fields, name }
      }
    })
      .then(({ data }) => {
        if (data?.createQuestion?.data?.id) {
          addToOffer(data.createQuestion.data.id);
          reset();
        }
      })
      .catch(e => {
        console.log(e.toString());
        setError(e.toString());
      })
      .finally(() => setLoading(false));
  });

  const add = useCallback((type?: 'yes-no' | 'free-text' | 'multiple-choice') => () => {
    switch (type) {
      case 'yes-no':
        setValue('answer', [NEW_YES_NO_ANSWER]);
        break;
      case 'free-text':
        setValue('answer', [NEW_FREE_TEXT_ANSWER]);
        break;
      case 'multiple-choice':
        setValue('answer', [NEW_MULTIPLE_CHOICE_ANSWER]);
        break;
    }
  }, []);

  const remove = useCallback(() => {
    setValue('answer', null);
  }, []);

  const addOption = useCallback(() => {
    const ans = answer as QuestionFormState['answer'];
    if (ans?.[0].__typename === 'ComponentAnswerMultipleChoice') {
      const options = [...ans[0].options, { answer: '', preferred: false }];
      setValue('answer', [{ ...ans[0], options }]);
    }
  }, [answer]);

  const removeOption = useCallback((index: number) => {
    const ans = answer as QuestionFormState['answer'];
    if (ans?.[0].__typename === 'ComponentAnswerMultipleChoice') {
      const options = [...ans[0].options];
      options.splice(index, 1);
      setValue('answer', [{ ...ans[0], options }]);
    }
  }, [answer]);

  return <Stack spacing={5}>
    <Typography variant="beta">{label}</Typography>
    {!!error && <Box paddingTop={5} paddingBottom={5}>
      <Alert onClose={() => setError('')} closeLabel="Close alert" title="" variant="danger">{error}</Alert>
    </Box>}
    <form onSubmit={onSubmit} noValidate style={{ position: 'relative' }}>
      {loading && <Preloader />}
      <Grid gap={5}>
        <GridItem col={12}>
          <Input
            label="Frage"
            required
            error={errors.question?.message}
            {...register('question', { required: getError('question') })}
          />
        </GridItem>
        <GridItem col={12}>
          <Stack spacing={1}>
            <Flex justifyContent="space-between">
              <FieldLabel required>Antwort</FieldLabel>
              {!!answer && <IconButton onClick={remove} noBorder label="Delete" icon={<Trash />} />}
            </Flex>
            <Grid padding={5} gap={5} background="neutral150" hasRadius>
              {!answer && <GridItem col={6} s={12}>
                <Flex>
                  <AddButton type="Ja / Nein hinzufügen" onClick={add('yes-no')} />
                  <AddButton type="Freifeld hinzufügen" onClick={add('free-text')} />
                  <AddButton type="Multiple Choice hinzufügen" onClick={add('multiple-choice')} />
                </Flex>
              </GridItem>}
              {answer?.[0].__typename === 'ComponentAnswerYesNo' && <>
                <GridItem col={6} s={12}>
                  <Controller
                    name={'answer.0.preferred'}
                    control={control}
                    render={({ field }) => <Select
                      {...field}
                      label="Preferred"
                      options={[
                        { value: 'none', label: 'none' },
                        { value: 'yes', label: 'yes' },
                        { value: 'no', label: 'no' }
                      ]} />}
                  />
                </GridItem>
              </>}
              {answer?.[0].__typename === 'ComponentAnswerFreeText' && <>
                <GridItem col={6} s={12}>
                  <Controller
                    name={'answer.0.required'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => <Toggle {...field} label="Required" />} />
                </GridItem>
              </>}
              {answer?.[0].__typename === 'ComponentAnswerMultipleChoice' && <>
                <GridItem col={6}>
                  <Controller
                    name={'answer.0.onlyOneAnswer'}
                    control={control}
                    render={({ field }) => <Toggle {...field} label="Only one answer" />} />
                </GridItem>
                <GridItem col={6}>
                  <Flex justifyContent="end" alignItems="flex-end">
                    <Button onClick={addOption}>Add option</Button>
                  </Flex>
                </GridItem>
                {answer[0].options.map((_, index) => (
                  <GridItem col={6} s={12} key={index}>
                    <Stack
                      spacing={2} borderWidth="1px" borderStyle="solid" borderColor="neutral200" padding={2} hasRadius>
                      <Input
                        label="Option"
                        required
                        error={errors.answer?.[0]?.options?.[index]?.answer?.message}
                        {...register(`answer.0.options.${index}.answer`, { required: getError('answer') })}
                      />
                      <Flex justifyContent="space-between">
                        <Controller
                          name={`answer.0.options.${index}.preferred`}
                          control={control}
                          render={({ field }) => <Checkbox {...field} label="preferred" />} />
                        {index > 1 && <IconButton
                          onClick={() => removeOption(index)} variant="ghost" label="Delete" icon={<Trash />} />}
                      </Flex>
                    </Stack>
                  </GridItem>
                ))}
              </>}
            </Grid>
          </Stack>
        </GridItem>
        <GridItem col={12}>
          <Flex justifyContent="end">
            <Button type="submit" variant="default" size="L">Add</Button>
          </Flex>
        </GridItem>
      </Grid>
    </form>
  </Stack>;
};

import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';
import { Field, FieldError, FieldLabel, FieldHint } from '@strapi/design-system/Field';
import { Box } from '@strapi/design-system/Box';
import { Stack } from '@strapi/design-system/Stack';
import { forwardRef, useCallback } from 'react';
import './TextEditor.scss';
import { ControllerRenderProps } from 'react-hook-form';

type TextEditorProps = {
  label?: string
  required?: boolean
  hint?: string
  error?: string
  labelAction?: JSX.Element
} & ControllerRenderProps<any>;

export const TextEditor = forwardRef<HTMLDivElement, TextEditorProps>(({
  label,
  required = false,
  hint,
  error,
  labelAction,
  value,
  onChange,
  ...rest
}, ref) => {
  const change = useCallback((evt, editor) => {
    const data = editor.getData();
    onChange(data);
  }, []);

  return <Field error={error} hint={hint}>
    <Stack spacing={1}>
      <FieldLabel action={<Box paddingLeft={2}>{labelAction}</Box>} required={required}>{label}</FieldLabel>
      <div className={!!error ? 'error' : ''} ref={ref} tabIndex={-1}>
        <CKEditor
          {...rest}
          editor={Editor}
          data={value}
          onChange={change} />
      </div>
      <FieldHint />
      <FieldError />
    </Stack>
  </Field>
});

import { Box } from '@strapi/design-system/Box';
import { Button } from '@strapi/design-system/Button';
import { Stack } from '@strapi/design-system/Stack';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  QuestionEntity,
  useGetQuestionsQuery
} from '../../generated/graphql-types';
import { setLoading } from '../../store/features/offer/offerSlice';
import { useAppDispatch } from '../../store/hooks';
import { Confirmation } from '../Confirmation';
import { List } from './List';
import { QuestionModal } from './QuestionModal';

export const Questions: FC<{ questions: string[], setQuestions: (value: string[]) => void }> = ({
  questions: newQuestions,
  setQuestions: setNewQuestions
}) => {
  const [cqIndex, setCqIndex] = useState<number | null>(null);
  const [aqIndex, setAqIndex] = useState<number | null>(null);
  const [toRemove, setToRemove] = useState(false);
  const onConfirm = useRef<(() => void) | null>(null);
  const dispatch = useAppDispatch();
  const [isNewQuestion, setIsNewQuestion] = useState(false);

  const {
    data: questionsData,
    loading: questionsLoading,
    refetch: questionsRefetch
  } = useGetQuestionsQuery({ notifyOnNetworkStatusChange: true });

  const currentQuestions: QuestionEntity[] = useMemo(
    () => questionsData?.questions?.data.filter(({ id }) => id && newQuestions.includes(id)) ?? [],
    [questionsData, newQuestions]
  );

  const availableQuestions: QuestionEntity[] = useMemo(
    () => questionsData?.questions?.data.filter(({ id }) => id && !newQuestions?.includes(id)) ?? [],
    [questionsData, newQuestions]
  );

  const addToOffer = useCallback((questionId: string) => {
    setNewQuestions([...newQuestions, questionId]);
  }, [newQuestions]);

  const removeFromOffer = useCallback((questionId: string) => {
    setNewQuestions(newQuestions.filter(id => id !== questionId) as string[]);
  }, [newQuestions]);

  const addNewQuestion = useCallback((questionId: string) => {
    setNewQuestions([...newQuestions, questionId]);
    questionsRefetch();
  }, [newQuestions]);

  useEffect(() => {
    dispatch(setLoading(questionsLoading));
  }, [questionsLoading]);

  useEffect(() => {
    return () => {
      dispatch(setLoading(false));
    }
  }, []);

  return <>
    <Box paddingTop={5} background="neutral0">
      <Stack spacing={10}>
        <List
          label="Aktuelle Fragen"
          questions={currentQuestions}
          index={cqIndex}
          setIndex={setCqIndex}
          remove={removeFromOffer}
          emptyText="Keine Fragen ausgewählt" />
        <List
          label="Verfügbare Fragen"
          questions={availableQuestions}
          index={aqIndex}
          setIndex={setAqIndex}
          add={addToOffer}
          emptyText="Keine verfügbaren Fragen" />
      </Stack>
      <Box paddingTop={10}>
        <Button size="L" onClick={() => setIsNewQuestion(true)}>Neue Frage hinzufügen</Button>
      </Box>
    </Box>
    <QuestionModal isVisible={isNewQuestion} setIsVisible={setIsNewQuestion} onSelect={addNewQuestion} />
    <Confirmation
      message="Möchten Sie wirklich die Frage löschen?"
      isShow={toRemove}
      setIsShow={setToRemove}
      onConfirm={onConfirm.current} />
  </>;
};

import { FieldAction } from '@strapi/design-system/Field';
import { Grid, GridItem } from '@strapi/design-system/Grid';
import { Tooltip } from '@strapi/design-system/Tooltip';
import Refresh from '@strapi/icons/Refresh';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import {
  useCheckOfferSlugLazyQuery,
  useGetActivityFieldsQuery,
  useGetCompaniesQuery,
  useGetEmploymentTimesQuery,
  useGetEmploymentTypesQuery,
  useGetPlacesQuery
} from '../../../generated/graphql-types';
import { getError } from '../../../helpers/errors';
import { getChangedFields } from '../../../helpers/getChangedFields';
import { getOptions } from '../../../helpers/getOptions';
import { getOfferSlug } from '../../../helpers/slugify';
import { useAppSelector } from '../../../store/hooks';
import { LOCALE, StepContext } from '../../../types';
import { Input } from '../../Inputs/Input';
import { Select } from '../../Inputs/Select';
import { FillButton } from '../FillButton';
import { Step } from './step';

export const Step1: FC = () => {
  const { save, submit } = useOutletContext<StepContext>();
  const { id = null } = useParams<'id'>();
  const [searchParams] = useSearchParams();
  const locale = searchParams.get('locale') ?? LOCALE.DE;

  const {
    title,
    company,
    place,
    field_of_activities,
    employment_times,
    type_of_employments,
    remote,
    priority,
    slug
  } = useAppSelector(state => state.offer.form);

  const [checkOfferSlug] = useCheckOfferSlugLazyQuery();
  const { data: companiesData } = useGetCompaniesQuery();
  const { data: placesData } = useGetPlacesQuery();
  const { data: activityFieldsData } = useGetActivityFieldsQuery();
  const { data: employmentTypesData } = useGetEmploymentTypesQuery();
  const { data: employmentTimesData } = useGetEmploymentTimesQuery();

  const isNew = useMemo(() => !id, [id]);
  const isDE = useMemo(() => locale === LOCALE.DE, [locale]);

  const companies = useMemo(
    () => getOptions(companiesData?.companies?.data),
    [companiesData]
  );

  const places = useMemo(
    () => getOptions(placesData?.places?.data),
    [placesData]
  );

  const activityFields = useMemo(
    () => getOptions(activityFieldsData?.fieldOfActivities?.data),
    [activityFieldsData]
  );

  const employmentTypes = useMemo(
    () => getOptions(employmentTypesData?.typeOfEmployments?.data),
    [employmentTypesData]
  );

  const employmentTimes = useMemo(
    () => getOptions(employmentTimesData?.employmentTimes?.data),
    [employmentTimesData]
  );

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
    formState: { errors, isDirty, dirtyFields }
  } = useForm({
    reValidateMode: 'onBlur',
    defaultValues: {
      title,
      company,
      place,
      field_of_activities,
      employment_times,
      type_of_employments,
      remote,
      priority,
      slug
    }
  });

  const cleanCallback = useCallback(() => {
    reset({
      title: '',
      company: null,
      place: null,
      field_of_activities: [],
      employment_times: [],
      type_of_employments: [],
      remote: null,
      priority: 0,
      slug: ''
    }, { keepDirty: true });
  }, []);

  const resetCallback = useCallback(() => {
    reset({
      title,
      company,
      place,
      field_of_activities,
      employment_times,
      type_of_employments,
      remote,
      priority,
      slug
    });
  }, []);

  const onSubmit = handleSubmit((fields) => {
    submit(getChangedFields(fields, dirtyFields));
  });

  const generateSlug = useCallback(async () => {
    const title = getValues('title');
    const slug = await getOfferSlug(`${title || `offer`}${locale && locale !== 'de' ? `-${locale}` : ``}`);
    setValue('slug', slug, { shouldDirty: true });
    trigger('slug');
  }, [locale]);

  const checkSlug = useCallback(async (newSlug) => {
    if (!isNew && newSlug === slug) {
      return true;
    }
    const { data } = await checkOfferSlug({ variables: { slug: newSlug } });
    return !data?.offers?.data?.[0]?.id || getError('slug', 'unique');
  }, [isNew, slug]);

  const saveCallback = () => {
    trigger().then((isValid) => {
      if (isValid) save(getChangedFields(getValues(), dirtyFields));
    });
  };

  useEffect(() => {
    reset({
      title,
      company,
      place,
      field_of_activities,
      employment_times,
      type_of_employments,
      remote,
      priority,
      slug
    });
  }, [
    title,
    company,
    place,
    field_of_activities,
    employment_times,
    type_of_employments,
    remote,
    priority,
    slug
  ]);

  return <Step
    title="Stellenbezeichnung"
    onSubmit={onSubmit}
    cleanCallback={cleanCallback}
    resetCallback={resetCallback}
    isDirty={isDirty}>
    <input id="save" hidden onClick={saveCallback} />
    <Grid gap={5}>
      <GridItem col={12}>
        <Input
          label="Titel"
          required
          error={errors.title?.message}
          {...register('title', { required: getError('title') })}
          labelAction={<FillButton field="title" setValue={setValue} />} />
      </GridItem>

      {isDE && <>
        <GridItem col={6} s={12}>
          <Controller
            name="company"
            control={control}
            render={({ field }) => <Select {...field} label="Unternehmen" options={companies} required={true} />} />
        </GridItem>
        <GridItem col={6} s={12}>
          <Controller
            name="place"
            control={control}
            render={({ field }) => <Select {...field} label="Arbeitsort" options={places} />} />
        </GridItem>

        <GridItem col={6} s={12}>
          <Controller
            name="field_of_activities"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Art der Anstellung"
                isMulti={true}
                options={activityFields} />
            )} />
        </GridItem>
        <GridItem col={6} s={12}>
          <Controller
            name="employment_times"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Arbeitszeit"
                isMulti={true}
                options={employmentTimes} />
            )} />
        </GridItem>
        <GridItem col={6} s={12}>
          <Controller
            name="type_of_employments"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Tätigkeitenbereich"
                isMulti={true}
                options={employmentTypes} />
            )} />
        </GridItem>

        <GridItem col={6} s={12} />

        <GridItem col={6} s={12}>
          <Controller
            name="remote"
            control={control}
            render={({ field }) => <Select
              {...field}
              label="Remote Arbeitsplatz"
              options={[
                { value: 'full', label: 'Full' },
                { value: 'half', label: 'Half' },
                { value: 'not', label: 'Not' }
              ]}
            />} />
        </GridItem>
        <GridItem col={6} s={12}>
          <Input
            label="Priorität"
            type="number"
            min={0}
            max={100}
            step={1}
            {...register('priority', { valueAsNumber: true })} />
        </GridItem>
      </>}

      <GridItem col={12}>
        <Input
          label="URL-Schlüssel"
          required
          error={errors.slug?.message}
          {...register('slug', {
            required: getError('slug'),
            validate: { unique: async (v) => await checkSlug(v) }
          })}
          action={<Tooltip description="Regenerate slug" position="left">
            <FieldAction label="Regenerate" onClick={generateSlug}>
              <Refresh fontSize={20} />
            </FieldAction>
          </Tooltip>}
          labelAction={<FillButton field="slug" setValue={setValue} />} />
      </GridItem>
    </Grid>
  </Step>;
};
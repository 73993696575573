import { Flex } from '@strapi/design-system/Flex';
import { Tag } from '@strapi/design-system/Tag';
import Cross from '@strapi/icons/Cross';
import { FC, useCallback } from 'react';
import { getTranslation } from '../../../helpers/getTranslation';
import { FilterField, FilterState } from '../../../types';

export const Tags: FC<{
  filterState: FilterState,
  setFilterState: (state: FilterState) => void
}> = ({ filterState, setFilterState }) => {
  const remove = useCallback((field: FilterField) => {
    const newFilters = { ...filterState };
    delete newFilters[field];
    setFilterState(newFilters);
  }, [filterState]);

  return !!filterState ? <Flex paddingBottom={3} wrap="wrap">
    {Object.entries(filterState)?.map(([field, { labels }], i) => (
      <Tag
        key={i}
        marginRight={2}
        marginBottom={2}
        icon={<Cross onClick={() => remove(field as FilterField)} />}
        onClick={() => null}>
        {`${getTranslation(field)}: ${labels.join(' | ')}`}
      </Tag>
    ))}
  </Flex> : null;
}
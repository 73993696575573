import { Alert } from '@strapi/design-system/Alert';
import { Box } from '@strapi/design-system/Box';
import { Button } from '@strapi/design-system/Button';
import { Flex } from '@strapi/design-system/Flex';
import { BaseHeaderLayout } from '@strapi/design-system/Layout';
import { LinkButton } from '@strapi/design-system/LinkButton';
import { Stack } from '@strapi/design-system/Stack';
import Check from '@strapi/icons/Check';
import Eye from '@strapi/icons/Eye';
import { useCallback, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PREVIEW_SECRET, PREVIEW_URL } from '../../config';
import { useGetOfferLazyQuery, useUpdateOfferMutation } from '../../generated/graphql-types';
import { resetData, resetForm } from '../../store/features/offer/offerSlice';
import { setStep } from '../../store/features/steps/stepsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { LOCALE, STEP } from '../../types';
import { Select } from '../Inputs/Select';
import { Layout } from '../Layout';
import { OfferForm } from '../OfferForm';
import { Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8 } from '../OfferForm/Steps';
import { Preloader } from '../Preloader';

export const Offer = () => {
  const navigate = useNavigate();
  const { id = null } = useParams<'id'>();
  const [searchParams, setSearchParams] = useSearchParams();
  const localeParam = searchParams.get('locale');
  const title = useAppSelector(state => state.offer.form.title);
  const dispatch = useAppDispatch();

  const [getOffer, { data, loading, error, refetch }] = useGetOfferLazyQuery();
  const [updateOffer] = useUpdateOfferMutation();

  const offer = useMemo(() => data?.offer?.data ?? null, [data]);
  const isPublished = useMemo(() => !!offer?.attributes?.publishedAt, [offer]);

  const titleStr = useMemo(() => !!offer ? offer.attributes?.title : '', [offer]);
  const locale = useMemo(() => localeParam || offer?.attributes?.locale, [localeParam, offer]);

  const previewUrl = useMemo(
    () => {
      const url = `${PREVIEW_URL}?secret=${PREVIEW_SECRET}`;
      if (locale === LOCALE.DE) {
        if (!!offer?.attributes?.slug) {
          return `${url}&slug=${offer.attributes.slug}`;
        }
      }
      const slug = offer?.attributes?.localizations?.data.find(l => l.attributes?.locale === 'de')?.attributes?.slug;
      if (slug) {
        return `${url}&slug=${slug}&locale=${locale}`;
      }
      return null;
    },
    [offer, locale]
  );

  const togglePublish = useCallback(() => {
    if (id) {
      updateOffer({
        variables: {
          id,
          data: { publishedAt: isPublished ? null : new Date().toISOString() }
        }
      })
        .then(() => refetch());
    }
  }, [id, isPublished]);

  const localeChange = useCallback((locale: LOCALE) => {
    const localeId = offer?.attributes?.localizations?.data.find(l => l.attributes?.locale === locale)?.id;
    if (localeId) navigate(`/job/${localeId}?locale=${locale}`);
    else if (offer?.id) navigate(`/job/create?locale=${locale}&for=${offer.id}`);
  }, [offer]);

  const cancel = useCallback(() => {
    dispatch(setStep(STEP.FIRST));
    dispatch(resetForm());
    dispatch(resetData());
    navigate('/');
  }, []);

  useEffect(() => {
    if (id) {
      getOffer({ variables: { id } })
        .then(({ data }) => {
          if (!data?.offer?.data) {
            navigate('/');
          }
        });
    }
  }, [id]);

  useEffect(() => {
    if (locale) {
      setSearchParams({ locale });
    }
  }, [locale]);

  useEffect(() => {refetch()}, [title]);

  return <Layout
    header={<BaseHeaderLayout
      primaryAction={<Stack spacing={2}>
        <Stack horizontal spacing={2} justifyContent="end">
          {!!previewUrl && <LinkButton
            size="L" variant="ghost" startIcon={<Eye style={{ width: '20px', height: '20px' }} />}
            href={previewUrl}>Vorschau anzeigen</LinkButton>}
          {!!id &&
            <Button
              size="L" variant="secondary" onClick={togglePublish}
              {...!isPublished && { startIcon: <Check /> }}>
              {isPublished ? 'Entwurf' : 'Veröffentlichen'}
            </Button>}
          <Button size="L" variant="tertiary" onClick={cancel}>Abbrechen</Button>
          <Button
            as="label"
            htmlFor="save"
            size="L"
            variant="default"
            style={{ borderRadius: '4px', cursor: 'pointer' }}>
            Speichern
          </Button>
        </Stack>
        <Flex justifyContent="end">
          <Box width="150px">
            <Select
              name="locales"
              value={locale}
              onChange={v => localeChange(v)}
              isClearable={false}
              options={[
                { value: "de", label: "German (de)" },
                { value: "en", label: "English (en)" }
              ]}
              placeholder="Select locale"
            />
          </Box>
        </Flex>
      </Stack>}
      title={titleStr} />}>
    {!!error && <Box paddingTop={5} paddingBottom={5}>
      <Alert onClose={() => null} closeLabel="Close alert" title="" variant="danger">{error.toString()}</Alert>
    </Box>}
    {loading && <Preloader />}
    <Routes>
      <Route
        path="/"
        element={!!offer ? <OfferForm key={offer.id} offer={offer.attributes} refetch={refetch} /> : null}>
        <Route path="1" element={<Step1 />} />
        <Route path="2" element={<Step2 />} />
        <Route path="3" element={<Step3 />} />
        <Route path="4" element={<Step4 />} />
        <Route path="5" element={<Step5 />} />
        <Route path="6" element={<Step6 />} />
        <Route path="7" element={<Step7 />} />
        <Route path="8" element={<Step8 />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  </Layout>
};

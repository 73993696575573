import { configureStore } from '@reduxjs/toolkit';
import offerReducer from './features/offer/offerSlice';
import stepsReducer from './features/steps/stepsSlice';

const preloadedState = (() => {
  const localOffer = localStorage.getItem('ks-offer-form');
  const offer = !!localOffer
    ? JSON.parse(localOffer)
    : null;
  const localSteps = localStorage.getItem('ks-offer-steps');
  const steps = !!localSteps ? JSON.parse(localSteps) : null;

  return {
    ...(offer && { offer }),
    ...(steps && { steps })
  };
})();

const store = configureStore({
  preloadedState,
  reducer: {
    offer: offerReducer,
    steps: stepsReducer
  }
});

store.subscribe(() => {
  localStorage.setItem('ks-offer-steps', JSON.stringify(store.getState().steps));
  localStorage.setItem('ks-offer-form', JSON.stringify(store.getState().offer));
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

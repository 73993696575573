import { Box } from '@strapi/design-system/Box';
import { Flex } from '@strapi/design-system/Flex';
import { Button } from '@strapi/design-system/Button';
import { Grid, GridItem } from '@strapi/design-system/Grid';
import { Typography } from '@strapi/design-system/Typography';
import Minus from '@strapi/icons/Minus';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import {
  PublicationState,
  useGetOffersQuery,
  useGetTagsQuery,
  useSearchOffersLazyQuery
} from '../../../generated/graphql-types';
import { getChangedFields } from '../../../helpers/getChangedFields';
import { getOptions } from '../../../helpers/getOptions';
import { useAppSelector } from '../../../store/hooks';
import { LOCALE, StepContext } from '../../../types';
import { Select, SelectAsync, SelectOption } from '../../Inputs/Select';
import { Toggle } from '../../Inputs/Toggle';
import { Step } from './step';

export const Step8: FC = () => {
  const { prev, save, submit } = useOutletContext<StepContext>();
  const { id } = useParams<'id'>();
  const {
    withoutCV,
    relatedOffers,
    tags
  } = useAppSelector(state => state.offer.form);
  const [searchParams] = useSearchParams();
  const locale = searchParams.get('locale') ?? LOCALE.DE;

  const { data: relatedOffersData } = useGetOffersQuery({
    variables: { filters: { id: { in: relatedOffers } }, publicationState: PublicationState.Preview }
  });
  const [searchRelatedOffers] = useSearchOffersLazyQuery();

  const { data: tagsData } = useGetTagsQuery();

  const tagsOptions = useMemo(
    () => getOptions(tagsData?.tags?.data),
    [tagsData]
  );

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { isDirty, dirtyFields }
  } = useForm({
    reValidateMode: 'onBlur',
    defaultValues: {
      withoutCV,
      relatedOffers: [] as SelectOption[],
      tags
    }
  });

  const offers = watch('relatedOffers');

  const cleanCallback = useCallback(() => {
    reset({
      withoutCV: false,
      relatedOffers: [],
      tags: []
    }, { keepDirty: true });
  }, []);

  const resetCallback = useCallback(() => {
    reset({
      withoutCV,
      relatedOffers: getOptions(relatedOffersData?.offers?.data, 'title'),
      tags
    });
  }, [relatedOffersData]);

  const onSubmit = handleSubmit((fields) => {
    const changedFields = getChangedFields(fields, dirtyFields);

    let offers = [];
    if (changedFields['relatedOffers']) {
      offers = changedFields['relatedOffers'].map(o => o.value);
    }

    submit({ ...changedFields, ...offers.length && { relatedOffers: offers } });
  });

  const getOptionsAsync = async (text) => {
    if (text) {
      const { data } = await searchRelatedOffers({ variables: { text } });
      return getOptions(data?.offers?.data.filter(o => o.id !== id), 'title');
    }
    return [];
  };

  const remove = useCallback((value: string) => {
    setValue('relatedOffers', offers.filter(o => o.value !== value));
  }, [offers]);

  useEffect(() => {
    if (relatedOffersData) {
      setValue('relatedOffers', getOptions(relatedOffersData.offers?.data, 'title'));
    }
  }, [relatedOffersData]);

  const saveCallback = () => {
    trigger().then((isValid) => {
      if (isValid) save(getChangedFields(getValues(), dirtyFields));
    });
  };

  useEffect(() => {
    reset({
      withoutCV,
      relatedOffers: getOptions(relatedOffersData?.offers?.data, 'title'),
      tags
    });
  }, [
    withoutCV,
    relatedOffers,
    tags
  ]);

  return <Step
    title="Sonstiges"
    onSubmit={onSubmit}
    prevCallback={prev}
    cleanCallback={cleanCallback}
    resetCallback={resetCallback}
    isDirty={isDirty}
    submitText="Speichern">
    <input id="save" hidden onClick={saveCallback} />
    <Grid gap={5}>
      {locale === LOCALE.DE && <>
        <GridItem col={3} s={6} xs={12}>
          <Controller
            name="withoutCV"
            control={control}
            render={({ field }) => <Toggle {...field} label="Bewerbung ohne Lebenslauf möglich?" />} />
        </GridItem>
        <GridItem col={9} s={6} />
        <GridItem col={6} s={12}>
          <Controller
            name="relatedOffers"
            control={control}
            render={({ field }) => <SelectAsync
              {...field}
              isMulti
              cacheOptions
              defaultOptions
              label={`Verknüpfte Stellenanzeigen (${offers.length})`}
              loadOptions={getOptionsAsync}
            />} />
          <Box as="ul" paddingTop={5}>
            {offers.map(offer => (
              <Box as="li" key={offer.value}>
                <Flex justifyContent="space-between">
                  <Typography variant="pi" fontWeight="bold">{offer.label}</Typography>
                  <Button variant="ghost" label="Remove" onClick={() => remove(offer.value)}>
                    <Minus style={{ width: '10px', height: '10px' }} />
                  </Button>
                </Flex>
              </Box>
            ))}
          </Box>
        </GridItem>
        <GridItem col={6} s={12}>
          <Controller
            name="tags"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label={`Tags (${getValues('tags').length})`}
                isMulti={true}
                options={tagsOptions} />
            )} />
        </GridItem>
      </>}
    </Grid>
    {locale === 'en' && <Typography variant="delta">Keine Übersetzungen nötig</Typography>}
  </Step>;
};
import {
  CarouselInput as SCarouselInput,
  CarouselSlide,
  CarouselImage,
  CarouselActions
} from '@strapi/design-system/CarouselInput';
import { IconButton } from '@strapi/design-system/IconButton';
import { Box } from '@strapi/design-system/Box';
import { Flex } from '@strapi/design-system/Flex';
import { Typography } from '@strapi/design-system/Typography';
import Plus from '@strapi/icons/Plus';
import Trash from '@strapi/icons/Trash';
import PicturePlus from '@strapi/icons/PicturePlus';
import { forwardRef, useCallback, useMemo, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { STRAPI_URL } from '../../config';
import { useGetFilesQuery } from '../../generated/graphql-types';
import { SelectAsset } from '../SelectAsset';
import styles from './CarouselInput.module.scss';

type CarouselInputProps = {
  label?: string
  secondaryLabel?: string
  required?: boolean
  hint?: string
  error?: string
  multiple?: boolean
  labelAction?: JSX.Element
} & ControllerRenderProps<any>;

export const CarouselInput = forwardRef<HTMLDivElement, CarouselInputProps>(({
  label,
  secondaryLabel,
  required = false,
  hint,
  error,
  multiple = true,
  labelAction,
  name,
  value: ids = multiple ? [] : null,
  onChange,
  onBlur,
  ...rest
}, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isSelect, setIsSelect] = useState(false);

  const { data } = useGetFilesQuery({
    variables: { ids, pagination: { limit: 100 } },
    skip: !ids?.length
  });

  const images = useMemo(
    () => data?.uploadFiles?.data.filter(({ attributes }) => !!attributes) ?? [],
    [data]
  );

  const handleNext = () => {
    setSelectedIndex(current => current < images.length - 1 ? current + 1 : 0);
  };

  const handlePrevious = () => {
    setSelectedIndex(current => current > 0 ? current - 1 : images.length - 1);
  };

  const select = useCallback((ids: string[]) => {
    const value = multiple ? ids : (ids?.[0] ?? '');
    onChange(value);
    onBlur();
  }, [multiple, name]);

  const remove = useCallback(() => {
    if (ids && typeof ids === 'string') {
      onChange(null);
    } else {
      const id = images?.[selectedIndex].id;
      setSelectedIndex(selectedIndex > 0 ? selectedIndex - 1 : 0)
      if (id) {
        onChange((ids as string[]).filter(e => e !== id));
      }
    }
    onBlur();
  }, [images, ids, selectedIndex]);

  return <div tabIndex={-1} ref={ref}>
    <SCarouselInput
      label={label}
      labelAction={<Box paddingLeft={2}>{labelAction}</Box>}
      required={required}
      selectedSlide={selectedIndex}
      secondaryLabel={secondaryLabel}
      previousLabel="Previous slide"
      nextLabel="Next slide"
      onNext={handleNext}
      onPrevious={handlePrevious}
      error={error}
      hint={hint}
      {...!!error && { borderColor: 'danger600' }}
      {...!!images.length && {
        actions: <CarouselActions>
          <IconButton onClick={() => setIsSelect(true)} label="Create" icon={<Plus />} />
          <IconButton onClick={remove} label="Delete" icon={<Trash />} />
        </CarouselActions>
      }}>
      {!!images.length
        ? images.map(({ id, attributes }) => (
          <CarouselSlide key={id} label={`1 of ${images.length} slides`}>
            <CarouselImage
              className={styles.Image}
              src={`${STRAPI_URL}${attributes!.formats?.thumbnail.url || attributes!.url}`}
              alt={`1 of ${images.length} slides`} />
          </CarouselSlide>
        ))
        : <button type="button" className={styles.AddAsset} onClick={() => setIsSelect(true)}>
          <Flex direction="column">
            <Box paddingBottom={2}><PicturePlus fontSize={24} /></Box>
            <Typography variant="pi" fontWeight="bold">Keine Einträge. Klicken um einen neuen Eintrag hinzuzufügen.</Typography>
          </Flex>
        </button>}
    </SCarouselInput>
    <SelectAsset
      isVisible={isSelect}
      setIsVisible={setIsSelect}
      selected={ids}
      onSelect={select}
      multiple={multiple} />
  </div>;
});

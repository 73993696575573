import { ModalLayout, ModalBody, ModalHeader, ModalFooter } from '@strapi/design-system/ModalLayout';
import { Typography } from '@strapi/design-system/Typography';
import { Button } from '@strapi/design-system/Button';
import { Grid, GridItem } from '@strapi/design-system/Grid';
import { Tabs, Tab, TabGroup, TabPanels, TabPanel } from '@strapi/design-system/Tabs';
import { Flex } from '@strapi/design-system/Flex';
import { Box } from '@strapi/design-system/Box';
import PicturePlus from '@strapi/icons/PicturePlus';
import { FC, FormEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { STRAPI_URL } from '../../config';
import { useUserContext } from '../../contexts/userContext/userContext';
import { useGetFilesQuery } from '../../generated/graphql-types';
import { Pagination } from '../Pagination';
import { Card } from './Card';
import { CardFile } from './CardFile';
import styles from './SelectAsset.module.scss';

type SelectAssetProps = {
  isVisible: boolean
  setIsVisible: (value: boolean) => void
  selected?: string | string[] | null
  onSelect?: (values: string[]) => void
  multiple?: boolean
}

export const SelectAsset: FC<SelectAssetProps> = ({
  isVisible,
  setIsVisible,
  selected: initSelected = [],
  onSelect,
  multiple = true
}) => {
  const { token } = useUserContext();
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [selected, setSelected] = useState<string[]>([]);
  const [isAdd, setIsAdd] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const { data, refetch } = useGetFilesQuery({
    variables: { pagination: { page, pageSize } }
  });

  const total = useMemo(() => data?.uploadFiles?.meta.pagination.total ?? 0, [data]);

  const { data: selectedData } = useGetFilesQuery({
    variables: { ids: selected, pagination: { limit: 100 } },
    skip: !selected.length
  });

  const cancel = useCallback(() => {
    if (isAdd) {
      setIsAdd(false);
    } else {
      setIsVisible(false);
    }
  }, [isAdd]);

  const confirm = useCallback(() => {
    onSelect?.(selected);
    setIsVisible(false);
  }, [selected]);

  const select = useCallback((id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter(s => s !== id));
    } else {
      setSelected([...(multiple ? selected : []), id]);
    }
  }, [selected, multiple]);

  const remove = useCallback((file: File) => {
    const newFiles = files.filter(f => f.name !== file.name);
    setFiles(newFiles);
  }, [files]);

  const change: FormEventHandler<HTMLInputElement> = useCallback(({ target }) => {
    const inputFiles = (target as HTMLInputElement).files;
    if (inputFiles?.length) {
      setFiles([...files, ...Array.from(inputFiles)]);
      setIsForm(false);
    }
  }, [files]);

  const upload = useCallback(() => {
    const data = new FormData();
    files.forEach(file => data.append('files', file));

    fetch(`${STRAPI_URL}/api/upload`, {
      method: 'POST',
      body: data,
      headers: {
        authorization: `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        const ids = data.map(e => e.id.toString());
        setSelected([...selected, ...ids]);
        setIsAdd(false);
        refetch();
      })
  }, [files, selected]);

  useEffect(() => {
    if (isVisible) {
      setSelected(
        initSelected
          ? typeof initSelected === 'string'
            ? [initSelected]
            : initSelected
          : []
      );
    }
  }, [isVisible, initSelected]);

  useEffect(() => {
    if (!pageCount && data?.uploadFiles?.meta.pagination.pageCount) {
      setPageCount(data.uploadFiles.meta.pagination.pageCount);
    }
  }, [data]);

  return isVisible
    ? <>
      <ModalLayout className={styles.SelectAsset} onClose={() => setIsVisible(false)} labelledBy="title">
        <ModalHeader>
          <Typography fontWeight="bold" textColor="neutral800" as="h2" id="title">
            Select assets
          </Typography>
        </ModalHeader>
        <ModalBody className={styles.Content}>
          {isAdd
            ? files.length && !isForm
              ? <>
                <Flex justifyContent="space-between">
                  <Typography variant="pi">{files.length} selected</Typography>
                  <Button onClick={() => setIsForm(true)}>Add more assets</Button>
                </Flex>
                <Grid>
                  {files.map((file, index) =>
                    <GridItem key={index} padding={5} col={3} s={6} xs={12}>
                      <CardFile file={file} onRemove={() => remove(file)} />
                    </GridItem>
                  )}
                </Grid>
              </>
              : <form>
                <Box
                  className={styles.UploadZone}
                  padding={5} background="neutral150" borderColor="neutral500" borderStyle="dashed" borderWidth="2px"
                  hasRadius>
                  <input name="files" type="file" multiple={true} onChange={change} />
                  <Flex direction="column" justifyContent="center">
                    <Box paddingBottom={2}><PicturePlus fontSize={48} /></Box>
                    <Box paddingBottom={2}>
                      <Typography variant="omega" fontWeight="bold">Drag & Drop here or</Typography>
                    </Box>
                    <Button>Browse files</Button>
                  </Flex>
                </Box>
              </form>
            : <TabGroup
              label="Tabs" id="tabs" variant="simple" initialSelectedTabIndex={selected.length ? 1 : 0}>
              <Flex justifyContent="space-between">
                <Tabs>
                  <Tab index={0}>Browse ({total})</Tab>
                  <Tab index={1} disabled={!selected.length}>Selected ({selected.length})</Tab>
                </Tabs>
                <Button onClick={() => setIsAdd(true)}>Add more assets</Button>
              </Flex>
              <TabPanels>
                <TabPanel>
                  <Grid>
                    {data?.uploadFiles?.data.map(({ id, attributes }) =>
                      id && attributes
                        ? <GridItem key={id} padding={5} col={3} s={6} xs={12}>
                          <Card
                            key={id} file={attributes} checked={selected.includes(id)}
                            onChange={() => select(id)} />
                        </GridItem>
                        : null
                    )}
                  </Grid>
                  {!!pageCount &&
                    <Flex padding={5} justifyContent="end">
                      <Pagination page={page} pageCount={pageCount} asButton={true} setPage={setPage} />
                    </Flex>
                  }
                </TabPanel>
                <TabPanel>
                  <Grid>
                    {selectedData?.uploadFiles?.data.map(({ id, attributes }) =>
                      id && attributes
                        ? <GridItem key={id} padding={5} col={3} s={6} xs={12}>
                          <Card
                            key={id} file={attributes} checked={selected.includes(id)}
                            onChange={() => select(id)} />
                        </GridItem>
                        : null
                    )}
                  </Grid>
                </TabPanel>
              </TabPanels>
            </TabGroup>}
        </ModalBody>
        <ModalFooter
          startActions={<Button onClick={cancel} variant="tertiary">Cancel</Button>}
          endActions={<>
            {(isAdd ? !isForm : true) && <Button onClick={isAdd && !isForm ? upload : confirm}>Finish</Button>}
          </>} />
      </ModalLayout>
    </>
    : null;
};
